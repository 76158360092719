import './Subheader.scss'
import Component from 'navigation/component/Component'
import { ModulesMappping } from 'core/modulesMap'
import SubheaderUtilities from 'components/subheader-utilities/SubheaderUtilities'

type SubheaderType = {

}

class Subheader extends Component<SubheaderType> {
  initialized (): void {
    super.initialized()
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      subheaderUtilities: ['.subheader-utilities', SubheaderUtilities]
    }
  }
}

export default Subheader
