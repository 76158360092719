import store from 'stores'

import config from './config'

type NewsletterSubscriptionType = {
  interested_in: string
  title: string
  first_name: string
  last_name: string
  email: string
  country: string
  language: string
  consent: boolean
}

export const newBackInStockAlert = async ({ email, productId }: {email:string, productId:string}) => {
  const apiKey = config.klaviyo.key

  const w = window as any
  try {
    if (!apiKey) throw new Error('API key not found')
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        revision: '2024-07-15',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        data: {
          type: 'back-in-stock-subscription',
          attributes: { profile: { data: { type: 'profile', attributes: { email } } }, channels: ['EMAIL'] },
          relationships: { variant: { data: { type: 'catalog-variant', id: `$shopify:::$default:::${productId}` } } }
        }
      })
    }

    await fetch(`${import.meta.env.VITE_KLAVIYO_BACK_IN_STOCK_ENDPOINT}?company_id=${apiKey}`, options)
    store.toaster.set({ title: w.variantStrings.backInStock.confirm })
  } catch (error) {
    store.toaster.set({ title: w.variantStrings.backInStock.error })
  }
}

export const newNewsletterSubscription = async (payload: NewsletterSubscriptionType) => {
  const apiKey = config.klaviyo.key

  const w = window as any
  try {
    if (!apiKey) throw new Error('API key not found')

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        revision: '2024-07-15',
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        data: {
          type: 'subscription',
          attributes: {
            profile: {
              data: {
                type: 'profile',
                attributes: {
                  email: payload.email,
                  first_name: payload.first_name,
                  last_name: payload.last_name,
                  title: payload.title,
                  properties: {
                    'Interested in': payload.interested_in,
                    Country: payload.country,
                    Language: payload.language
                  }

                }
              }
            }
          },
          relationships: {
            list: {
              data: {
                type: 'list',
                id: config.klaviyo.listId
              }
            }
          }
        }
      })
    }

    await fetch(`${import.meta.env.VITE_KLAVIYO_NEWSLETTER_ENDPOINT}?company_id=${config.klaviyo.key}`, options)
    store.toaster.set({ title: w.variantStrings.newsletter.confirm })
  } catch (error) {
    store.toaster.set({ title: w.variantStrings.newsletter.error })
  }
}
