import Component from 'navigation/component/Component'
import './BackInStockForm.scss'
import { bindMethod } from 'helpers/bind'
import { newBackInStockAlert } from 'core/mail'
import store from 'stores'

type BackInStockFormType = {
  refs: {
    submitter: HTMLButtonElement
  }
}

class BackInStockForm extends Component<BackInStockFormType> {
  initialized (): void {
    super.initialized()
  }

  bindEvents (add?: boolean): void {
    const method = bindMethod(add)
    this.el[method]('submit', (e) => this.onSubmit(e))
  }

  onSubmit (event: Event): void {
    event.preventDefault()
    const data = Object.fromEntries(new FormData(this.el as HTMLFormElement).entries()) as { email: string, productId: string }

    newBackInStockAlert(data)

    store.panel.set(null)
  }
}

export default BackInStockForm
