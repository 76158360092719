import Component from 'navigation/component/Component'
import './ProductShopTheLook.scss'
import { ModulesMappping } from 'core/modulesMap'
import ProductMiniCard from 'components/product-mini-card/ProductMiniCard'

type ProductShopTheLookType = {}

class ProductShopTheLook extends Component<ProductShopTheLookType> {
  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      productMiniCard: ['.product-mini-card', ProductMiniCard]
    }
  }
}

export default ProductShopTheLook
