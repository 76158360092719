import config from 'core/config'
import './Footer.scss'
import { ModulesMappping } from 'core/modulesMap'
import { bindMethod } from 'helpers/bind'
import Component from 'navigation/component/Component'
import mqStore from 'stores/mqStore'

type FooterType = {
  modules: {
  }
  refs: {
    footerAccordion: HTMLElement[]
    footerAccordionTitle: HTMLElement[]
    footerText: HTMLElement[]
  }
}

class Footer extends Component<FooterType> {
  initialized (): void {
    super.initialized()
    this.setAttributesLinks()
  }

  getModulesMap (): ModulesMappping {
    return {
      // newsletterForm: ['.footer-newsletter__form', Form]
    }
  }

  bindEvents (add?: boolean): void {
    const method = bindMethod(add)

    this.refs.footerAccordion?.forEach(a => {
      a[method]('click', this.onAccordionClick)
    })
  }

  setAttributesLinks (): void {
    if (!this.refs.footerText) return
    this.refs.footerText.forEach((el) => {
      const links = el.querySelectorAll('a')
      links.forEach((link) => {
        link.setAttribute('data-panel', config.routes.newsletterPanel)
        link.role = 'button'
        link.removeAttribute('href')
      })
    })
    this.unbindInternalRouter()
    this.bindInternalRouter()
  }

  onAccordionClick = ({ currentTarget }: any) => {
    if (!mqStore.tabletPortrait.get()) return
    currentTarget.classList.toggle('opened')
  }

  resize (): void {
    if (!mqStore.tabletPortrait.get()) return
    this.refs.footerAccordion?.[this.refs.footerAccordion.length - 1].classList.add('opened')
  }
}

export default Footer
