import Component from 'navigation/component/Component'
import './Accordion.scss'
import { bindMethod } from 'helpers/bind'
import { ModulesMappping } from 'core/modulesMap'
import MoreButton from 'components/more-button/MoreButton'
import mqStore from 'stores/mqStore'

type AccordionType = {
  refs: {
    accordionContent: HTMLElement
  }
  modules:{
    button: MoreButton
  }
}

class Accordion extends Component<AccordionType> {
  initialized (): void {
    super.initialized()
  }

  getModulesMap (): ModulesMappping {
    return {
      button: ['.more-button', MoreButton]
    }
  }

  bindEvents (add = true) {
    const method = bindMethod(add)

    this.el[method]('click', this.toggle)
  }

  toggle = () => {
    this.el.classList.toggle('opened')
    this.modules.button.el.classList.toggle('open')
  }

  resize (): void {
    (this.refs.accordionContent.parentNode as HTMLElement)?.style.setProperty('--height', `${this.refs.accordionContent.offsetHeight + (mqStore.tabletPortrait.get() ? 20 : 10)}px`)
  }
}
export default Accordion
