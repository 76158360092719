import { BaseComponentType } from 'navigation/component/Component'
import anime from 'animejs'
import store from 'stores'
import { bindMethod } from 'helpers/bind'
import mqStore from 'stores/mqStore'

import Page, { IPage } from './Page'

import './PanelPage.scss'
export default class SearchPage<
  Type extends BaseComponentType = BaseComponentType
> extends Page<Type> implements IPage {
  bindEvents (add = true) {
    const method = bindMethod(add)
    const refs = this.refs as any
    refs.searchClose?.[method]('click', this.onClose)
  }

  onClose = () => {
    store.search.set(null)
    store.menu.set(null)
  }

  show (previousPage?: string): Promise<void> {
    const isTabletPortrait = mqStore.tabletPortrait.get()
    const translate = !isTabletPortrait ? { translateX: ['-100%', 0] } : { translateY: ['100%', 0] }

    if (previousPage) {
      this.el.style.transform = 'none'
      return Promise.resolve()
    }

    return anime(Object.assign(({
      ...translate,
      easing: 'easeInOutQuad'
    }),
    {
      targets: this.el,
      delay: 0,
      duration: 300
    })).finished
  }

  hide (nextPage?: string): Promise<void> {
    const isTabletPortrait = mqStore.tabletPortrait.get()
    const translate = !isTabletPortrait ? { translateX: [0, '-100%'] } : { translateY: [0, '100%'] }
    if (nextPage) return Promise.resolve()
    return anime(Object.assign(({
      ...translate,
      easing: 'easeInOutQuad'
    }),
    {
      targets: this.el,
      delay: 0,
      duration: 200
    })).finished
  }
}
