import { BaseComponentType } from 'navigation/component/Component'
import VirtualPageManager from 'navigation/page-manager/VirtualPageManager'
import MainPage from 'navigation/pages/MainPage'
import { RequestOptions } from 'navigation/page-manager/PageManager'
import { State } from 'helpers/state'
import modulesMap, { ModulesMappping } from 'core/modulesMap'
import scroll from 'core/scroll'
import InternalPage from 'navigation/pages/InternalPage'

type InternalRouterType<Type extends BaseComponentType> = Type & {
  refs: { internalRouter: HTMLElement } & Type['refs']
  modules: { content: VirtualPageManager } & Type['modules']
}

class InternalRouter <
  Type extends BaseComponentType = BaseComponentType
> extends MainPage <InternalRouterType<Type>> {
  getModulesMap (): ModulesMappping {
    return {
      footer: modulesMap.footer
    }
  }

  getDefaultPageClass () {
    return InternalPage
  }

  getPageSelector () {
    return '.internal-page'
  }

  bindModules () {
    if (!this.pageManager) this.pageManager = this.initContentRouter()
    super.bindModules()
    if (!this.modules.content) this.modules.content = this.pageManager as VirtualPageManager
  }

  initContentRouter () {
    const store = new State<string | null>(null)

    return new VirtualPageManager(
      store,
      this.refs.internalRouter,
      this.getPageSelector(),
      this.getDefaultPageClass(),
      { pageManager: this.pageManager, parent: this }
    )
  }

  shouldRouteInternally (el: HTMLElement, pathName: string) {
    return true
  }

  async scrollToTop () {
    if (document.scrollingElement) await scroll.scrollTo(0)
  }

  async internalRouting (pathName: string, xhr: XMLHttpRequest, requestOptions: RequestOptions) {
    await this.scrollToTop()
    this.modules.content.forceRouteUpdate(pathName, xhr, requestOptions)
  }
}

export default InternalRouter
