import { StoreType, createStore } from 'helpers/state'

export type ProductVariant = {
  id: number
  title: string
  price: number
  available: boolean
  compareAtPrice: number
}

export type ProductState = {
  productId: string
  variantId: string | null
  variantLoading: boolean
}

export type ProductStore = StoreType<ProductState>
export type ProductContext = ProductStore

const createProductStore = (options: Partial<ProductState>) : ProductStore => {
  return createStore<ProductState>({
    ...options,
    variantLoading: false
  } as ProductState)
}

const createProductContext = (product: Partial<ProductState>) : ProductContext => {
  const store = createProductStore(product)
  return { ...store }
}

export { createProductContext }
