import Component from 'navigation/component/Component'
import './ProductGallery.scss'
import { bindMethod } from 'helpers/bind'
import anime from 'animejs'
import resize from 'helpers/resize'
import mqStore from 'stores/mqStore'
import { ModulesMappping } from 'core/modulesMap'
import scroll from 'core/scroll'
import store from 'stores'

type ProductGalleryType = {
  refs: {
    galleryStrip: HTMLElement
    galleryInner: HTMLElement
    galleryIndicators: HTMLElement[]
  }
}

class ProductGallery extends Component<ProductGalleryType> {
  declare private _touchStart: { clientY: number, scrollTop: number }
  declare private _locked: boolean
  declare private _galleryLimit: number

  getScrollRatio = (): number => {
    const gallery = this.refs.galleryInner
    return gallery.scrollTop / (gallery.scrollHeight - gallery.offsetHeight)
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap()
    }
  }

  bindRefs (target?: HTMLElement): void {
    super.bindRefs(target)
    if (!this.refs) this.refs = {} as ProductGalleryType['refs']
    this.refs.galleryStrip = this.el.parentElement?.querySelector('.product-gallery__strip-inner') as HTMLElement
  }

  bindEvents (add?: boolean): void {
    const method = bindMethod(add)
    this.refs.galleryInner?.[method]('scroll', this.onGalleryScroll)
    window?.[method]('scroll', this.onPageScroll)
    this.refs.galleryInner?.[method]('touchstart', this.onTouchStart as any)
    this.refs.galleryInner?.[method]('touchmove', this.onTouchMove as any, { passive: false })
    this.refs.galleryInner.scrollTop = 0

    this.refs.galleryStrip?.[method]('click', this.onGalleryStripClick)

    this.onGalleryScroll()
  }

  onGalleryScroll = (): void => {
    const ratio = this.getScrollRatio()
    const itemIndex = Math.round(ratio * (this.refs.galleryIndicators.length - 1))

    this.refs.galleryIndicators?.forEach((indicator, index) => {
      indicator.classList.toggle('active', index === itemIndex)
    })
  }

  onPageScroll = (): void => {
    const needWhiteHeader = !mqStore.tabletPortrait.get() || scroll.scrollTop() > this._galleryLimit
    store.headerTransparent.set(!needWhiteHeader)
    this.refs.galleryStrip.classList.toggle('sticky', needWhiteHeader)
  }

  onTouchStart = (event: TouchEvent): void => {
    this._touchStart = {
      clientY: event.touches?.[0]?.clientY,
      scrollTop: document.scrollingElement?.scrollTop || 0
    }
  }

  onTouchMove = (event: TouchEvent): void => {
    if (!mqStore.tabletPortrait.get()) return
    if (!document.scrollingElement) return

    if (this._locked) return event.preventDefault()

    const delta = event.touches?.[0]?.clientY - this._touchStart.clientY

    const isEndReached = this.getScrollRatio() >= 0.999 && delta < 0
    const hasScroll = this._touchStart.scrollTop > 0

    const threshold = 50

    if (hasScroll) { // Scroll to top
      const shouldScroll = delta > threshold
      if (shouldScroll) this.scrollup()
    } else { // Scroll to bottom
      if (!isEndReached) this._touchStart.clientY = event.touches?.[0]?.clientY
      const shouldScroll = isEndReached && delta < -threshold

      if (shouldScroll) this.scrolldown()
    }
  }

  scrollProps = () => ({
    targets: document.scrollingElement,
    duration: 500,
    easing: 'easeOutQuad',
    begin: () => { this._locked = true },
    complete: () => { this._locked = false }
  })

  scrollLimit = (): number => (resize.height() * 0.6) >> 0

  scrolldown = (): void => {
    anime({
      scrollTop: this.scrollLimit(),
      ...this.scrollProps()
    })
  }

  scrollup = (): void => {
    anime({
      scrollTop: 0,
      ...this.scrollProps()
    })
  }

  onGalleryStripClick = (event: Event): void => {
    if (scroll.scrollTop() >= this.scrollLimit()) this.scrollup()
    else this.scrolldown()
  }

  resize (): void {
    super.resize()
    this._galleryLimit = this.refs?.galleryInner?.offsetHeight
    this.onPageScroll()
  }
}

export default ProductGallery
