import 'scrolling-element'
import anime from 'animejs'
import { TinyEmitter } from 'tiny-emitter'
import detect from 'helpers/detect'
import browser from 'helpers/browser'
import store from 'stores'

let scrollTop = document.scrollingElement?.scrollTop || 0
let preventScroll = false
let disableOverflow = false
let freezeDetection = false

const emitter = new TinyEmitter()

document.scrollingElement?.classList.add('scrolling-element')

const init = () => {
  testScroll(document.scrollingElement?.scrollTop || 0)
  window.addEventListener('scroll', pageScroll, { passive: false })

  document.body.addEventListener('touchmove', preventDefault, { passive: false })
  document.body.addEventListener('mousewheel', preventDefault, { passive: true })
  document.body.addEventListener('wheel', preventDefault, { passive: true })
  document.body.addEventListener('DOMMouseScroll', preventDefault, { passive: true })
}

const preventDefault = (event: Event) => {
  const preventParent = browser.getParent(event.target as HTMLElement, '.scrolling-wrapper')
  if (preventScroll && !preventParent) event.preventDefault()
}

const lock = (overflow = false) => {
  if (overflow) {
    if (document.documentElement.classList.contains('no-scroll')) return
    const scrollTop = document.documentElement.scrollTop
    document.documentElement.classList.add('no-scroll')
    if (detect.desktop) document.body.scrollTop = scrollTop
    disableOverflow = true
  } else { preventScroll = true }
}

const unlock = (overflow = false) => {
  emitter.emit('unlock')
  if (overflow) {
    if (!document.documentElement.classList.contains('no-scroll')) return
    const scrollTop = document.body.scrollTop
    document.documentElement.classList.remove('no-scroll')
    if (detect.desktop) document.documentElement.scrollTop = scrollTop
    disableOverflow = false
  } else { preventScroll = false }
}

const pageScroll = (event?: Event) => {
  const _scrollTop = document.scrollingElement?.scrollTop || 0
  if (_scrollTop === scrollTop) return

  if (freezeDetection) return

  testScroll(_scrollTop)
  scrollTop = _scrollTop
  emitter.emit('scroll', event)
}

let startScrollDown = false
let hasScrollDown = false
let lastScrollTop = Infinity
const scrollClassname = 'scroll-down'
const topOffset = 100
const offset = 50

const testScroll = (newScrollTop: number) => {
  if (newScrollTop === scrollTop) return
  if (newScrollTop > Math.max(0, scrollTop)) scrollDown(newScrollTop)
  else scrollUp(newScrollTop)
}

const scrollDown = (newScrollTop: number) => {
  if (!startScrollDown) {
    startScrollDown = true
    lastScrollTop = newScrollTop
  }

  if (hasScrollDown || Math.abs(lastScrollTop - newScrollTop) < offset) return
  document.body.classList.add(scrollClassname)
  document.body.classList.remove('not-' + scrollClassname)
  store.scrollDown.set(true)

  lastScrollTop = newScrollTop
  hasScrollDown = true
}

const scrollUp = (newScrollTop: number) => {
  if (startScrollDown) {
    startScrollDown = false
    lastScrollTop = newScrollTop
  }

  if (!hasScrollDown || (Math.abs(lastScrollTop - newScrollTop) < offset && newScrollTop > topOffset)) return
  document.body.classList.remove(scrollClassname)
  document.body.classList.add('not-' + scrollClassname)

  store.scrollDown.set(false)

  hasScrollDown = false
}

const scrollTo = (_scrollTop: number, { duration = 800, easing = 'easeOutQuad', target }: {
  duration?: number,
  easing?: string,
  target?: HTMLElement
} = {}) => {
  // if (animating) return Promise.resolve()
  return anime({
    targets: target || document.scrollingElement,
    scrollTop: [target ? target.scrollTop : scrollTop, _scrollTop],
    // scrollTop: _scrollTop,
    duration,
    easing,
    // complete: () => (animating = false),
    update: () => pageScroll()
  }).finished
}

const getScrollTop = () => {
  if (detect.desktop && disableOverflow) return document.body.scrollTop
  return scrollTop
}

const scroll = {
  init,
  lock,
  unlock,
  locked: () => (preventScroll || disableOverflow),
  freezeDection: () => { freezeDetection = true },
  unfreezeDetection: () => { freezeDetection = false },
  scrollTo,
  on: (cb: (v: number) => void) => emitter.on('scroll', cb),
  off: (cb: (v: number) => void) => emitter.off('scroll', cb),
  emitter,
  scrollTop: getScrollTop,
  reset: () => unlock()
}

export default scroll
