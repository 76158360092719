import Component from 'navigation/component/Component'
import './Filters.scss'
import { bindEmitterMethod, bindMethod } from 'helpers/bind'
import store from 'stores'
import Form from 'components/form/Form'
import { ModulesMappping } from 'core/modulesMap'
import { debounce } from 'lodash-es'

type FiltersType = {
    modules: {
      form: any
    },
    refs: {
      buttonLabel: HTMLElement
      submitForm: HTMLElement,
      filtersClose: HTMLButtonElement
    }
}

class Filters extends Component<FiltersType> {
  getModulesMap (): ModulesMappping {
    return {
      form: ['.filters__form', Form]
    }
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    const emitterMethod = bindEmitterMethod(add)
    this.modules.form[emitterMethod]('input', this.onUpdate)
    this.modules.form[emitterMethod]('reset', this.reset)
    this.modules.form[emitterMethod]('submit', this.close)
    this.refs.filtersClose[method]('click', this.close)
    store.filtersOpened.listen(this.toggle)
  }

  onUpdate = debounce(async () => {
    this.refs.submitForm.classList.add('loading')
    const request = await fetch(window.location.pathname + this.modules.form.getFormValuesAsURL() + '&section_id=filter-count')
    const label = this.refs.submitForm?.querySelector('.button__label-inner') as HTMLElement

    if (!label) {
      this.refs.submitForm.classList.remove('loading')
      return
    }

    label.innerHTML = await request.text()
    this.refs.submitForm.classList.remove('loading')
  }, 500)

  close = () => {
    store.filtersOpened.set(false)
  }

  toggle = (value:boolean) => {
    this.el.classList.toggle('opened', value)
  }

  reset = (e: Event): void => {
    this.modules.form?.setSubmitted(false)
    this.modules.form.inputs.forEach((input: any) => {
      if (input.checked) input.checked = false
    })

    this.modules.form.submit()
    this.close()
  }
}
export default Filters
