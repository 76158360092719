import mapboxgl from 'mapbox-gl'
import config from 'core/config'

import './Map.scss'

import 'mapbox-gl/dist/mapbox-gl.css'
import Component from 'navigation/component/Component'

mapboxgl.accessToken = 'pk.eyJ1IjoiY2VycnV0aTE4ODEiLCJhIjoiY20wcDV3cjMwMDJmdjJrcXNpd2VpcTk1biJ9.d2rX1LXcwoip2P-vFnGm4Q'

type MapType = {
  refs: {
    zoomIn: HTMLButtonElement
    zoomOut: HTMLButtonElement
  }
}

class Map extends Component<MapType> {
  private map: mapboxgl.Map
  private markers: mapboxgl.Marker[]
  private popups: mapboxgl.Popup[]

  constructor (el: HTMLElement, options: any) {
    super(el, options)
    this.map = new mapboxgl.Map({
      container: this.el,
      style: 'mapbox://styles/cerruti1881/cm0papojl00jo01qycqv2hq1u'
    })
  }

  initialized (): void {
    this.resize()
  }

  bindEvents (add = true) {
    super.bindEvents(...arguments)
    const methodNative = add ? 'addEventListener' : 'removeEventListener'
    this.refs.zoomOut[methodNative]('click', this.zoomOut)
    this.refs.zoomIn[methodNative]('click', this.zoomIn)
  }

  zoomIn = () => this.map && this.map.zoomIn()

  zoomOut = () => this.map && this.map.zoomOut()

  deleteMarkersAndPopUp = (markers: string | any[], popups: { remove: () => void }[]) => {
    setTimeout(() => {
      for (let i = 0; i < markers.length; i++) {
        const marker = markers[i].getElement()
        marker.onclick = null
        popups[i].remove()
        markers[i].remove()
      }
    }, 1000)
  }

  createMarkersAndPopups = (points: [any, any, any][]) => {
    if (this.markers) this.deleteMarkersAndPopUp(this.markers, this.popups)
    this.markers = []
    this.popups = []

    points.forEach(([point, text, button], i) => {
      const wrapper = document.createElement('div')
      wrapper.innerHTML = text
      if (button) wrapper.appendChild(button)

      const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(wrapper)

      this.popups.push(popup)
      const el = document.createElement('div')
      el.className = 'map__marker'
      el.onclick = this.onMarkerClicked(i)

      const marker = new mapboxgl.Marker(el)
        .setLngLat(point)
        .addTo(this.map)
        .setPopup(popup)

      this.markers.push(marker)
    })
  }

  onMarkerClicked = (i: number) => (event: any) => {
    this.emit('clicked', i)
  }

  setMarkers (points: any[]) {
    const bounds = new mapboxgl.LngLatBounds()
    points.forEach(p => bounds.extend(p[0]))

    const options = { maxZoom: 12, animate: true, padding: 200 }

    if (points.length) {
      if (!this.markers) options.animate = false
      this.map.fitBounds(bounds, options)
    }

    this.createMarkersAndPopups(points)
  }

  resize (): void {
    this.map.resize()
  }
}

export default Map
