import modulesMap from 'core/modulesMap'
import { BaseComponentType } from 'navigation/component/Component'

import MainPage from './MainPage'

export default class InternalPage<Type extends BaseComponentType = BaseComponentType> extends MainPage<Type> {
  constructor (el:HTMLElement, options:any) {
    super(el, options)
    el.classList.add('internal-page')
  }

  getModulesMap () {
    return Object.assign({}, modulesMap)
  }

  /* SHOW */
  askPreload (previousPage) {
    if (previousPage) this.el.style.opacity = '0'
    return super.askPreload(...arguments)
  }

  preload (previousPage) {
    if (previousPage) return super.preload(...arguments)
    else return Promise.resolve()
  }

  getChildrenShowDelay () {
    const { previousMainPage, previousPage } = this.options
    return super.getChildrenShowDelay() + ((!previousPage && !previousMainPage) ? 800 : 0)
  }

  getTranslateY (hide:boolean) {
    return hide ? '-40vh' : '40vh' as any
  }

  show (previousPage) {
    if (!previousPage) {
      this.el.style.opacity = '1'
      this.showChildren()
      return Promise.resolve()
    } else {
      return super.show(previousPage)
    }
  }
}
