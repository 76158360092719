import { defer } from 'lodash-es'
import { BaseComponentType } from 'navigation/component/Component'
import scroll from 'core/scroll'

import Page from './Page'

export default class MainPage<
  Type extends BaseComponentType = BaseComponentType
> extends Page<Type> {
  /* LOAD */
  async askPreload (previousPage?: string) {
    this.el.classList.add('appearing')
    await super.askPreload(previousPage)
  }

  /* SHOW */
  show (previousPage?:string) {
    this.resize()
    scroll.lock()

    scroll.unlock()
    defer(() => this.resize())
    this.navigateToHash()

    return super.show()
  }

  async preload (previousPage?: string) {
    // await this.preloadImages()
  }

  navigateToHash () {
    if (window.location.hash)
      document.querySelector(window.location.hash)?.scrollIntoView({ behavior: 'smooth' })
  }

  getChildrenShowDelay () {
    return 1
  }

  showChildren () {
    setTimeout(() => this.invoke('show'), this.getChildrenShowDelay())
  }

  transitionComplete () {
    this.el.classList.remove('appearing')
    super.transitionComplete()
  }

  /* HIDE */

  hide (nextPage?:string) {
    this.el.classList.add('disappearing')
    document.documentElement.style.setProperty('--scroll', `${scroll.scrollTop()}px`)

    return super.hide()
  }
}
