import InternalRouter from 'navigation/pages/InternalRouter'
import store from 'stores'
import { bindMethod } from 'helpers/bind'
import Collection from 'pages/collection/Collection'
import './Search.scss'
import './SearchHeader.scss'
import './SearchSubheader.scss'

type SearchType = {
  refs : {
    searchClose: HTMLElement
  }
}

class Search extends Collection<SearchType> {
  declare private searchTerms: string
  declare private searchCount: string

  initialized (): void {
    this.searchTerms = this.el.dataset.searchTerms || ''
    this.searchCount = this.el.dataset.searchCount || ''
    super.initialized()
  }

  bindEvents (add?: boolean): void {
    super.bindEvents(add)
    const method = bindMethod(add)
    this.refs.searchClose[method]('click', this.onSearchClose)
  }

  onSearchClose = () => {
    store.menu.set(null)
  }

  shouldRouteInternally (el: HTMLElement, pathName: string): boolean {
    return el?.getAttribute('data-search') === this.searchTerms
  }

  async internalRouting (...args: Parameters<InternalRouter['internalRouting']>) {
    return super.internalRouting(...args)
  }
}

Search.pageName = 'Search'

export default Search
