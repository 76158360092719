import './ShowSubheader.scss'
import { bindMethod } from 'helpers/bind'
import scroll from 'core/scroll'
import { ModulesMappping } from 'core/modulesMap'
import Component from 'navigation/component/Component'

type ShowSubheaderType = {
  refs: {
    closePopin: HTMLButtonElement
  }
}

class ShowSubheader extends Component<ShowSubheaderType> {
  private popinLooksIndex = null

  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap()
    }
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    this.refs.closePopin[method]('click', this.handleClosePopin)
  }

  handleClosePopin = () => {
    scroll.unlock(true)
    this.parent?.el.classList.remove('show-popin')
    this.refs.closePopin.classList.add('hidden')
    this.bindModules()
  }
}

export default ShowSubheader
