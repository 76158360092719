import InternalRouter from 'navigation/pages/InternalRouter'
import MainPage from 'navigation/pages/MainPage'
import { ProductContext, createProductContext } from 'stores/productStore'
import { IComponentClass, ModulesMappping } from 'core/modulesMap'
import { defer, each } from 'lodash-es'
import { bindMethod } from 'helpers/bind'
import router from 'core/router'
import Accordion from 'components/accordion/Accordion'
import cart from 'stores/cart'
import store from 'stores'
import mqStore from 'stores/mqStore'

import ProductGallery from './product-gallery/ProductGallery'
import ProductInfos from './product-infos/ProductInfos'
import ProductsRelated from './products-related/ProductsRelated'
import ProductShopTheLook from './product-shop-the-look/ProductShopTheLook'
import ProductMobileBar from './product-mobile-bar/ProductMobileBar'
import ProductZoom from './product-zoom/ProductZoom'
import ProductMobileSizesButton from './product-mobile-sizes-button/ProductMobileSizesButton'

import './Product.scss'
import './product-colors/ProductColors.scss'
import './product-sizes/ProductSizes.scss'
import './product-mobile-sizes/ProductMobileSizes.scss'

export type ProductType = {
  refs: {
    productGalleryImages: HTMLImageElement[]
    productMobileBar: HTMLElement
    productHeader: HTMLElement
  }
  modules: {
    accordion: Accordion[]
    productInfos: ProductInfos
    productGallery: ProductGallery
    productsRelated: ProductsRelated
    productShopTheLook: ProductShopTheLook
    productMobileBar: ProductMobileBar
    productZoom: ProductZoom
  },
  context: ProductContext
}

class Product extends MainPage<ProductType> {
  getReloadMap (): ModulesMappping {
    return {
      productInfos: ['.product-infos', ProductInfos],
      productMobileBar: ['self', ProductMobileBar]
    }
  }

  getModulesMap (): ModulesMappping {
    return {
      ...this.getReloadMap(),
      ...super.getModulesMap(),
      productZoom: ['.product-zoom', ProductZoom],
      productGallery: ['.product-gallery', ProductGallery],
      productsRelated: ['.products-related', ProductsRelated],
      productShopTheLook: ['.product-shop-the-look', ProductShopTheLook],
      productMobileSizesButton: ['.product-mobile-sizes-button', ProductMobileSizesButton]
    }
  }

  getModuleParams (el: HTMLElement, componentConstructor: IComponentClass): Record<string, any> {
    return {
      ...super.getModuleParams(el, componentConstructor),
      addToCart: () => this.handleAddToCart()
    }
  }

  constructor (...args: ConstructorParameters<typeof InternalRouter>) {
    super(...args)
    this.detectHeader()
    this.createContext()
  }

  initialized (): void {
    super.initialized()
    this.modules.accordion[0].toggle()
  }

  createContext () {
    const productId = this.el.getAttribute('data-product') as string
    const variantId = this.el.getAttribute('data-variant')
    return createProductContext({
      productId,
      variantId
    })
  }

  bindEvents (add: boolean): void {
    super.bindEvents(add)
    const method = bindMethod(add)

    this.refs.productGalleryImages.forEach(img => {
      img[method]('click', this.onGalleryClick)
    })
  }

  onGalleryClick = (e: any): void => {
    if (mqStore.tabletPortrait.get()) return
    const position = this.refs.productGalleryImages.indexOf(e.currentTarget as HTMLImageElement)

    if (mqStore.tabletPortrait.get()) return

    this.modules.productZoom?.activate(position)
  }

  handleAddToCart = async (): Promise<void> => {
    await cart.addToCart({
      id: this.context.variantId.get()!,
      getCart: true
    })
    // store.panel.set(config.routes.cartPanel)
  }

  onVariantLoading = (loading: boolean): void => {
    this.el.classList.toggle('loading', loading)
  }

  shouldRouteInternally (el: HTMLElement): boolean {
    const productId = el?.getAttribute('data-product') as string
    return productId === this.context.productId.get()
  }

  async internalRouting (pathName: string, xhr: XMLHttpRequest) {
    const document = xhr.response as Document

    const page = document.querySelector('.page') as HTMLElement
    this.context.variantId.set(page.getAttribute('data-variant') || '')

    each(this.getReloadMap(), ([selector], key) => {
      const el = page.querySelector(selector) as HTMLElement
      const module = (this.modules as any)[key]
      module?.refreshContent?.(el)
    })

    defer(() => {
      this.resize()
      this.bindRefs()
      this.bindInternalRouter()
      router.updatePageLinks()
    })
  }

  resize (): void {
    super.resize()
    const header = this.refs.productHeader
    const height = header.offsetHeight + header.offsetTop
    this.el.style.setProperty('--product-mobile-bar-height', `${height}px`)
    this.detectHeader()
  }

  detectHeader () {
    const isMobile = mqStore.tabletPortrait.get()
    store.headerCollapsed.set(isMobile)
    store.headerTransparent.set(isMobile)
    store.headerOverlap.set(isMobile)
  }
}

Product.pageName = 'Product'

export default Product
