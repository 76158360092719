import Component from 'navigation/component/Component'
import { bindMethod } from 'helpers/bind'
import './ProductMobileSizesButton.scss'
import scroll from 'core/scroll'

type ProductMobileSizesButtonType = {
  refs: {
    productMobileSizesButton: HTMLButtonElement
  }
}

class ProductMobileSizesButton extends Component<ProductMobileSizesButtonType> {
  bindEvents (add?: boolean): void {
    const method = bindMethod(add)
    this.refs.productMobileSizesButton[method]('click', this.open)
  }

  open = (): void => {
    (this.parent as any)?.el.classList.add('open-size')
    scroll.lock()
  }
}

export default ProductMobileSizesButton
