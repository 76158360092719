import { createStore } from 'helpers/state'
import PageManager from 'navigation/page-manager/PageManager'

import { CollectionGridTypes } from './collectionStore'

export type ToasterType = {
  title: string
  type?:'cart'|'wishlist'|null
  link?: {
    href: string
    label: string
  }
  externalLink?: {
    href: string
    label: string
  }
  image?: string
} | null

type BaseStore = {
  lang: string
  path: string | null
  loading: boolean

  error: string | null
  toaster: ToasterType

  menu: string | boolean | null
  panel: string | null
  popin: string | null
  search: string | null

  scrollDown: boolean
  blurredOverlay: boolean

  filtersOpened:boolean
  view: CollectionGridTypes

  routers : Record<string, PageManager>

  headerBig: boolean
  headerColor: 'black' | 'white'
  headerTransparent: boolean
  headerCollapsed: boolean
  headerOverlap: boolean
}

const header = document.querySelector('.header')

const store = createStore<BaseStore>({
  lang: 'fr',
  path: null,
  loading: false,
  scrollDown: false,

  error: null,
  toaster: null,

  menu: null,
  popin: null,
  panel: null,
  view: 'grid4',
  search: null,

  filtersOpened: false,
  blurredOverlay: false,
  routers: {},

  headerBig: header?.classList.contains('big') || false,
  headerColor: header?.classList.contains('white') ? 'white' : 'black',
  headerTransparent: header?.classList.contains('transparent') || false,
  headerCollapsed: header?.classList.contains('collapsed') || false,
  headerOverlap: header?.classList.contains('overlap') || false
})

export default store
