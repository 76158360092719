import { IComponentClass, ModulesMappping } from 'core/modulesMap'
import './WishlistPanel.scss'
import PanelPage from 'navigation/pages/PanelPage'

import WishlistItems from '../wishlist-items/WishlistItems'

type WishlistPanelType = {
  refs: {

  }
}

const shopifySection = 'panel-wishlist'

class WishlistPanel extends PanelPage<WishlistPanelType> {
  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      items: ['.wishlist-items', WishlistItems]
    }
  }

  getModuleParams (el: HTMLElement, componentConstructor: IComponentClass): Record<string, any> {
    return {
      ...super.getModuleParams(el, componentConstructor),
      section: shopifySection
    }
  }
}

export default WishlistPanel
