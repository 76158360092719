import { IComponentClass, ModulesMappping } from 'core/modulesMap'
import './NewsletterPanel.scss'
import PanelPage from 'navigation/pages/PanelPage'

import NewsletterForm from './newsletter-form/NewsletterForm'

type NewsletterPanelType = {
  refs: {
  }
}

const shopifySection = 'panel-newsletter'

class NewsletterPanel extends PanelPage<NewsletterPanelType> {
  getModuleParams (el: HTMLElement, componentConstructor: IComponentClass): Record<string, any> {
    return {
      ...super.getModuleParams(el, componentConstructor),
      section: shopifySection
    }
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      newsletterForm: ['.newsletter-form', NewsletterForm]
    }
  }
}

export default NewsletterPanel
