import './NextCategories.scss'
import Component from 'navigation/component/Component'

type NextCategoriesType = {

}

class NextCategories extends Component<NextCategoriesType> {

}

export default NextCategories
