import Component from 'navigation/component/Component'
import './SizeGuideTables.scss'
import { bindMethod } from 'helpers/bind'
import { clamp } from 'lodash-es'

type Mouse = {
  x:number
  prevX:number
  startTime:number | null
}

type SizeGuideTablesType = {
  refs: {
    sizeGuideTable: HTMLElement[]
    sizeGuideInner: HTMLElement
    sizeGuideTab: HTMLElement[]
  }
}

class SizeGuideTables extends Component<SizeGuideTablesType> {
  private track: HTMLElement | undefined
  private width: number | undefined
  private delta:number = 0
  private mouse: Mouse = {
    x: 0,
    prevX: 0,
    startTime: null
  }

  private _grabbing:boolean = false
  private _moving:boolean = false

  private _scrollable:boolean = false

  private get scrollable () {
    return this._scrollable
  }

  private set scrollable (value:boolean) {
    this._scrollable = value
    if (this.track)
      this.track.parentElement?.classList.toggle('scrollable', value)
  }

  private get grabbing () {
    return this._grabbing
  }

  private set grabbing (value:boolean) {
    this._grabbing = value
    if (value) {
      document.body.style.cursor = 'grabbing'
      if (this.track)
        this.track.classList.add('grabbing')
    } else {
      document.body.style.cursor = 'default'
      if (this.track)
        this.track.classList.remove('grabbing')
    }
  }

  private get moving () {
    return this._moving
  }

  private set moving (value:boolean) {
    this._moving = value
    if (value) {
      this.refs.sizeGuideInner.classList.add('moving')
    } else {
      this.refs.sizeGuideInner.classList.remove('moving')
      this._grabbing = false
      this._moving = false
    }
  }

  buildScrollbar = () => {
    if (this.refs.sizeGuideInner.scrollWidth < this.refs.sizeGuideInner.clientWidth) return
    const wrapper = document.createElement('div')
    wrapper.classList.add('size-guide-tables__scrollbar')
    this.track = document.createElement('button')
    this.track.setAttribute('type', 'button')
    this.track.classList.add('size-guide-tables__track')
    this.track.style.width = this.refs.sizeGuideInner.clientWidth / this.refs.sizeGuideInner.scrollWidth * 100 + '%'
    this.width = this.refs.sizeGuideInner.clientWidth / this.refs.sizeGuideInner.scrollWidth * 100
    wrapper.appendChild(this.track)
    this.refs.sizeGuideInner.after(wrapper)
  }

  initialized (): void {
    this.buildScrollbar()
    this.bindEvents(true)
  }

  bindEvents (add?: boolean): void {
    const method = bindMethod(add)
    if (this.track)
      this.track[method]('mousedown', this.onTrackMouseDown)

    window[method]('mousemove', this.onTrackMouseMove)
    window[method]('mouseup', this.onTrackMouseUp)

    if (this.track)
      this.track[method]('touchstart', this.onTouchStart)

    window[method]('touchmove', this.onTouchMove)
    window[method]('touchend', this.onTouchEnd)

    if (this.refs.sizeGuideTable) {
      this.refs.sizeGuideTable.forEach((table) => {
        table[method]('touchstart', this.onTouchStart)
        table[method]('wheel', this.onWheel)
      })
    }

    this.refs.sizeGuideTab.forEach((tab) => {
      tab[method]('click', this.onTabClick)
    })
  }

  onTabClick = (e: any): void => {
    e.preventDefault()
    const target = e.target as HTMLElement
    if (target.classList.contains('active')) return
    const index = this.refs.sizeGuideTab.indexOf(target)
    this.refs.sizeGuideTab.forEach((tab) => tab.classList.remove('active'))
    target.classList.add('active')
    this.refs.sizeGuideTable.forEach((table) => table.classList.remove('active'))
    this.refs.sizeGuideTable[index].classList.add('active')
  }

  checkScrollable = () => {
    this.scrollable = this.refs.sizeGuideInner.scrollWidth > this.refs.sizeGuideInner.clientWidth
  }

  onWheel = (e: any): void => {
    e.preventDefault()
    e.stopPropagation()
    this.move(e.deltaX)
  }

  onScroll = () => {
    this.width = this.refs.sizeGuideInner.clientWidth / this.refs.sizeGuideInner.scrollWidth * 100
    const progress = this.refs.sizeGuideInner.scrollLeft / (this.refs.sizeGuideInner.scrollWidth - this.refs.sizeGuideInner.clientWidth)

    if (this.track)
      this.track.style.left = `${progress * (100 - this.width)}%`
  }

  onTouchStart = (e: any): void => {
    this.mouse = {
      x: e.touches[0].clientX,
      prevX: e.touches[0].clientX,
      startTime: Date.now()
    }
    this.grabbing = true
  }

  onTouchMove = (e: any): void => {
    if (this.grabbing) {
      if (!this.moving)
        this.moving = true
      const target = e.target as HTMLElement
      let direction:number = -1
      if (target.classList.contains('sizes__track'))
        direction = 1

      const delta = e.touches[0].clientX - this.mouse.x

      this.move(delta * direction)
      this.mouse.prevX = this.mouse.x
      this.mouse.x = e.touches[0].clientX
    }
  }

  onTouchEnd = (e: any): void => {
    if (this.mouse.startTime) {
      if (this.mouse.startTime + 100 < Date.now())
        e.preventDefault()

      this.grabbing = false
      this.moving = false
    }
  }

  onTrackMouseDown = (e: any): void => {
    this.mouse = {
      x: e.clientX,
      prevX: e.clientX,
      startTime: Date.now()
    }
    this.grabbing = true
  }

  onTrackMouseMove = (e: any): void => {
    if (this.grabbing) {
      if (!this.moving)
        this.moving = true

      const delta = e.clientX - this.mouse.x
      this.move(delta)
      this.mouse.prevX = this.mouse.x
      this.mouse.x = e.clientX
    }
  }

  onTrackMouseUp = (e: any): void => {
    if (this.mouse.startTime) {
      if (this.mouse.startTime + 100 < Date.now())
        e.preventDefault()

      this.grabbing = false
      this.moving = false
    }
  }

  move = (delta:number) => {
    const w = this.refs.sizeGuideInner.scrollWidth - this.refs.sizeGuideInner.clientWidth
    this.delta = clamp(this.delta - delta, -w, 0)
    this.refs.sizeGuideInner.scrollTo({ left: -this.delta })
    if (this.track && this.width)
      this.track.style.left = `${-this.delta / w * (100 - this.width)}%`
  }

  resize (): void {
    super.resize()
    this.checkScrollable()
    if (this.track)
      this.track.style.width = this.refs.sizeGuideInner.clientWidth / this.refs.sizeGuideInner.scrollWidth * 100 + '%'
    this.onScroll()
  }
}

export default SizeGuideTables
