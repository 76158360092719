import ProductGrid from 'components/product-grid/ProductGrid'
import { ModulesMappping } from 'core/modulesMap'
import InternalPage from 'navigation/pages/InternalPage'

class CollectionInner extends InternalPage {
  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      productGrid: ['.product-grid', ProductGrid]

    }
  }
}
export default CollectionInner
