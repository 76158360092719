import { uniq } from 'lodash-es'
import store from 'stores'
import persistentStore from 'stores/persistentStore'

const getItem = async (url:string) => {
  const separator = url.includes('?') ? '&' : '?'
  const sectionUrl = url += separator + 'section_id=wishlist-card-view'
  const response = await fetch(sectionUrl)
  return response.text()
}

const getItems = async () => {
  let html = ''
  const wishlist = persistentStore.wishlist.get()
  if (wishlist.length === 0) return ''
  const items = await Promise.allSettled(wishlist.map(async (item) => {
    return getItem(item)
  }))
  items.forEach((item) => {
    if (item.status === 'fulfilled')
      html += item.value
  })

  return html
}
const addToWishlist = async (url:string, name:string, featuredImage:string) => {
  const wishlist = persistentStore.wishlist.get().slice(0)
  wishlist.push(url)
  persistentStore.wishlist.set(uniq(wishlist))
  store.toaster.set({
    type: 'wishlist',
    title: name
  })
}
const removeFromWishlist = async (url:string) => {
  const wishlist = persistentStore.wishlist.get().slice(0)
  const index = wishlist.indexOf(url)
  wishlist.splice(index, 1)
  persistentStore.wishlist.set(uniq(wishlist))
}

const replaceInWishlist = (oldUrl:string, newUrl:string) => {
  const wishlist = persistentStore.wishlist.get().slice(0)
  const index = wishlist.indexOf(oldUrl)
  wishlist[index] = newUrl
  persistentStore.wishlist.set(uniq(wishlist))
  return index
}

export {
  getItem,
  getItems,
  addToWishlist,
  removeFromWishlist,
  replaceInWishlist
}
