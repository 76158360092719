import Component from 'navigation/component/Component'
import { bindMethod } from 'helpers/bind'
import './Carousel.scss'

type CarouselType = {
  refs: {
    images: HTMLImageElement[]
    trackingDots: HTMLElement[]
  }
  modules:{
  }
}

class Carousel extends Component<CarouselType> {
  private _currentIndex: number = 0
  private _interval: NodeJS.Timeout | null = null

  bindEvents (add = true) {
    const method = bindMethod(add)

    this.refs.trackingDots?.forEach((dot, index) => {
      dot[method]('click', () => this.handleDotClick(index, true))
    })

    if (this.refs.images.length <= 1) return
    if (this.el.children[0].getAttribute('has-carousel') === 'true')
      this.startInterval()
  }

  startInterval () {
    this._interval = setInterval(() => {
      this._currentIndex = (this._currentIndex + 1) % this.refs.images.length
      this.handleDotClick(this._currentIndex, false)
    }, 3000)
  }

  handleDotClick = (index: number, clear:boolean = false) => {
    if (this._interval && clear)
      clearInterval(this._interval)
      // this.startInterval()
    this._currentIndex = index
    this.refs.images.forEach((image, i) => {
      if (i === index) {
        image.classList.add('active')
        this.refs.trackingDots[i].classList.add('active')
      } else {
        image.classList.remove('active')
        this.refs.trackingDots[i].classList.remove('active')
      }
    })
  }

  resize (): void {
  }
}

export default Carousel
