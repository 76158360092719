import { ModulesMappping } from 'core/modulesMap'
import Component from 'navigation/component/Component'
import sizeStore from 'stores/sizeStore'
import store from 'stores'
import { bindMethod } from 'helpers/bind'
import cart from 'stores/cart'
import './Header.scss'
import resize from 'helpers/resize'
import math from 'helpers/math'
import scroll from 'core/scroll'

type HeaderType = {
  refs: {
    headerInner: HTMLElement;
    menuButtons: HTMLElement[];
    cartCount: HTMLElement;
    mobileMenuButton: HTMLElement;
  };
  modules: {
  };
};

class Header extends Component<HeaderType> {
  declare resizeObserver: ResizeObserver

  initialized (): void {
    super.initialized()
    // store.headerTransparent.set(true)
    // store.headerColor.set('white')
    // store.headerBig.set(true)
  }

  bindEvents (add: boolean): void {
    if (add) {
      this.resizeObserver = new ResizeObserver(this.onHeaderResize)
      this.resizeObserver.observe(this.refs.headerInner)
    } else {
      this.resizeObserver.disconnect()
    }

    const method = bindMethod(add)
    this.refs.menuButtons?.forEach(b => b[method]('click', this.toggleMenu))
    this.refs.mobileMenuButton?.[method]('click', this.toggleMobileMenu)
    cart.count.toggleListener(add, this.updateCartCount)
    store.menu.toggleListener(add, this.updateMenu)
    document[method]('scroll', this.onScroll)

    store.menu.toggleListener(add, this.updateHeader)
    store.headerTransparent.toggleListener(add, this.updateHeader)
    store.headerColor.toggleListener(add, this.updateHeader)
    store.headerCollapsed.toggleListener(add, this.updateHeader)
    store.headerOverlap.toggleListener(add, this.updateHeader)
    store.headerBig.toggleListener(add, this.updateBigHeader)
    this.updateHeader()
  }

  onScroll = () => {
    if (!document.scrollingElement || !store.headerBig.get() || scroll.locked()) return
    const progress = math.clamp(document.scrollingElement?.scrollTop / (resize.height()), 0, 1)
    this.el.style.setProperty('--logo-progress', progress.toString())
  }

  updateCartCount = (count: number) => {
    if (!this.refs.cartCount) return
    this.refs.cartCount.textContent = count.toString()
    this.refs.cartCount.dataset.count = count.toString()
  }

  toggleMenu = (event: Event) => {
    const id = (event.currentTarget as HTMLElement).dataset.id
    const newValue = store.menu.get() === id ? null : id as string
    store.menu.set(newValue)
  }

  toggleMobileMenu = (event: Event) => {
    const id = (event.currentTarget as HTMLElement).dataset.id
    const newValue = store.menu.get() ? null : id as string
    store.menu.set(newValue)
  }

  updateHeader = () => {
    const isWhite = store.headerColor.get() === 'white' && store.headerTransparent.get() // && !store.menu.get()
    this.el.classList.toggle('transparent', store.headerTransparent.get())
    this.el.classList.toggle('black', !isWhite)
    this.el.classList.toggle('white', isWhite)
    this.el.classList.toggle('collapsed', store.headerCollapsed.get())
    this.el.classList.toggle('overlap', store.headerOverlap.get())
    this.onScroll()
  }

  updateBigHeader = (big: boolean) => {
    this.el.classList.toggle('big', big)
  }

  updateMenu = (id: string | boolean | null) => {
    this.el.classList.toggle('menu-opened', !!id)

    if (id) this.el.dataset.menu = id as string
    else delete this.el.dataset.menu

    this.refs.menuButtons?.forEach(b => {
      b.classList.toggle('active', b.dataset.id === id)
    })
  }

  onHeaderResize = ([entry]: ResizeObserverEntry[]) => {
    const newHeight = this.refs.headerInner?.offsetHeight
    sizeStore.headerHeight.set(newHeight)
    document.documentElement.style.setProperty('--header-height', `${newHeight}px`)
  }

  getModulesMap (): ModulesMappping {
    return { }
  }
}

export default Header
