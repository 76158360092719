import Component from 'navigation/component/Component'
import './WishlistItem.scss'
import { ModulesMappping } from 'core/modulesMap'
import persistentStore from 'stores/persistentStore'
import { bindMethod } from 'helpers/bind'
import { removeFromWishlist } from 'actions/wishlistActions'
import cart from 'stores/cart'
import store from 'stores'
import config from 'core/config'
import SelectNative from 'components/select-native/SelectNative'

type WishlistItemType = {
  refs: {
    sizeSelect: HTMLSelectElement;
    wishlistRemove: HTMLButtonElement;
    wishlistAddToCart: HTMLButtonElement;
  };
  modules: {
    productSelect: SelectNative
  };
};

class WishlistItem extends Component<WishlistItemType> {
  private productUrl: string = ''

  initialized (): void {
    const card = this.el as HTMLElement
    this.productUrl = card.dataset.productUrl || ''

    this.bindRefs()
    this.bindModules()
    super.initialized()
  }

  getModulesMap (): ModulesMappping {
    return {
      productSelect: ['.wishlist-item__selectSize', SelectNative]
    }
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    this.refs.wishlistRemove[method]('click', this.onRemove)
    this.refs.wishlistAddToCart[method]('click', this.onAddToCart)
  }

  onRemove = (e: Event) => {
    e.stopPropagation()
    const wishlist = persistentStore.wishlist.get().slice(0)

    const entry = wishlist.find((entry) => entry.includes(this.productUrl))
    if (entry) {
      removeFromWishlist(entry)
      this.el.parentElement?.remove()
      this.emit('remove')
    }
  }

  onAddToCart = async (e: Event) => {
    this.refs.wishlistAddToCart.classList.add('loading')
    const variantId = this.modules.productSelect.value()

    await cart.addToCart({
      id: variantId,
      ghostSrc: (this.el as HTMLElement).dataset.featured
    })
    store.panel.set(config.routes.cartPanel)
    this.onRemove(e)
  }
}

export default WishlistItem
