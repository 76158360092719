import Accordion from 'components/accordion/Accordion'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import ButtonUnderlined from 'components/button-underlined/ButtonUnderlined'
import Button from 'components/button/Button'
import Carousel from 'components/carousel/Carousel'
import Footer from 'components/footer/Footer'
import Price from 'components/price/Price'
import ProductCard from 'components/product-card/ProductCard'
import SelectNative from 'components/select-native/SelectNative'
import Stockists from 'components/stockists/Stockists'
import Subheader from 'components/subheader/Subheader'
import TextButton from 'components/text-button/TextButton'
import Utils from 'components/utils/Utils'
import BlockHero from 'pages/brand/block-hero/BlockHero'
import BlockTextImage from 'pages/brand/block-text-image/BlockTextImage'
import ShowDescription from 'pages/show/show-description/ShowDescription'
import ShowGuests from 'pages/show/show-guests/ShowGuests'
import ShowHero from 'pages/show/show-hero/ShowHero'
import ShowLooks from 'pages/show/show-looks/ShowLooks'
import ShowSubheader from 'pages/show/show-subheader/ShowSubheader'
import WishlistFavButton from 'pages/wishlist/wishlist-fav-button/WishlistFavButton'

export interface IComponentClass {
  new (el: HTMLElement, options: any): any
}

export type ModuleMapping = [string, IComponentClass | null]
export type ModulesMappping = Record<string, ModuleMapping>

const modulesMap : ModulesMappping = {
  button: ['.button', Button],
  buttonUnderlined: ['.button-underlined', ButtonUnderlined],
  selectNative: ['.select-native', SelectNative],
  textButton: ['.text-button', TextButton],
  breadcrumb: ['.breadcrumb', Breadcrumb],
  subheader: ['.subheader', Subheader],
  productCard: ['.product-card', ProductCard],
  accordion: ['.accordion', Accordion],
  utils: ['section.utils', Utils],
  stockists: ['section.stockists', Stockists],
  showSubheader: ['section.show-subheader', ShowSubheader],
  showHero: ['section.show-hero', ShowHero],
  showDescription: ['section.show-description', ShowDescription],
  showLooks: ['section.show-looks', ShowLooks],
  showGuests: ['section.show-guests', ShowGuests],
  blockHero: ['section.block-hero', BlockHero],
  blockTextImage: ['section.block-text-image', BlockTextImage],
  carousel: ['.carousel', Carousel],
  footer: ['footer', Footer],
  price: ['.price', Price],
  wishlistFavButton: ['.wishlist-fav-button', WishlistFavButton]

}

export default modulesMap
