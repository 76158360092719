import Component from 'navigation/component/Component'
import store from 'stores'
import { template } from 'lodash-es'
import router from 'core/router'
import { ToasterType } from 'stores/store'
import './Toaster.scss'
import config from 'core/config'

type ToasterComponetType = {
  refs: {
    toasterContent: HTMLElement
  }
}

class Toaster extends Component<ToasterComponetType> {
  timeout?: ReturnType<typeof setTimeout>

  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  bindEvents (add = true) {
    store.toaster.toggleListener(add, this.showToaster)
  }

  showToaster = (toaster: ToasterType) => {
    if (!toaster) return

    this.el.classList.add('visible')
    if (this.timeout) clearTimeout(this.timeout)
    this.timeout = setTimeout(this.hideToaster, 3000)
    let cartUrl = '/cart'
    if (toaster.type === 'cart')
      cartUrl = config.locale + '/cart'

    let wishlistUrl = '/wishlist'
    const w = window as any
    if (toaster.type === 'wishlist') {
      wishlistUrl = config.locale + '/wishlist'
      toaster.title = `<span>${toaster.title}</span>` + ' ' + w.variantStrings.wishlist.added
    }
    const t = `
      <% if (toaster.image) { %>
        <img class="toaster__image" src="<%= toaster.image %>" />
      <% } %>
      <h4 class="toaster__title"><%= toaster.title %></h4>
      <% if (toaster.link) { %>
        <a class="toaster__link" href="<%= toaster.link.href %>"><%= toaster.link.label %></a>
      <% } %>
      <% if (toaster.externalLink) { %>
        <a class="toaster__link" href="<%= toaster.externalLink.href %>"><%= toaster.externalLink.label %></a>
      <% } %>
       <% if (toaster.type === 'cart') { %>
        <a class="toaster__link" data-panel="/<%= cartUrl %>?section_id=panel-cart">View</a>
      <% } %>
        <% if (toaster.type === 'wishlist') { %>
        <a class="toaster__link" data-panel="/<%= wishlistUrl %>?section_id=panel-wishlist">View</a>
      <% } %>
    `
    this.refs.toasterContent.innerHTML = template(t)({ toaster, cartUrl, wishlistUrl })
    requestAnimationFrame(() => {
      router.updatePageLinks()
      this.bindInternalRouter()
    })
  }

  hideToaster = () => {
    this.el.classList.remove('visible')
    store.error.set(null)
  }
}

export default Toaster
