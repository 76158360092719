import store from 'stores'
import scroll from 'core/scroll'
import sizeStore from 'stores/sizeStore'

import CustomPageManager from './CustomPageManager'

class MainPageManager extends CustomPageManager {
  resetScroll () {
    if (!document.scrollingElement) return
    document.scrollingElement.scrollTop = Math.min(document.scrollingElement.scrollTop, sizeStore.notificationHeight.get())
  }

  removePage () {
    this.resetScroll()
    super.removePage()
  }

  async initializePage (...args: Parameters<CustomPageManager['initializePage']>) {
    await super.initializePage(...args)
    store.loading.set(false)
  }

  extractPageInfo (page: Parameters<CustomPageManager['extractPageInfo']>[0]) {
    return super.extractPageInfo(page)
  }

  onRouteUpdate (...args: Parameters<CustomPageManager['onRouteUpdate']>) {
    const updated = super.onRouteUpdate(...args)
    if (updated !== true) return false
    store.loading.set(true)
    store.menu.set(null)
    scroll.unlock(true)
    return true
  }

  cancelTransition () {
    super.cancelTransition()
    store.loading.set(false)
  }

  triggerInternalRouting (...args: Parameters<CustomPageManager['triggerInternalRouting']>) {
    super.triggerInternalRouting(...args)
    store.loading.set(false)
  }

  async preloadPage (...args: Parameters<CustomPageManager['preloadPage']>) {
    await super.preloadPage(...args)
    store.loading.set(false)
  }

  createPage (...args: Parameters<CustomPageManager['createPage']>) {
    return super.createPage(...args)
  }
}

export default MainPageManager
