import { ModulesMappping } from 'core/modulesMap'
import InternalRouter from 'navigation/pages/InternalRouter'
import { CollectionContext, createCollectionContext } from 'stores/collectionStore'
import store from 'stores'
import ProductGrid from 'components/product-grid/ProductGrid'
import NextCategories from 'components/next-categories/NextCategories'
import Filters from 'components/filters/Filters'
import Subheader from 'components/subheader/Subheader'
import { BaseComponentType } from 'navigation/component/Component'

import CollectionInner from './CollectionInner'

type CollectionType<Type extends BaseComponentType> = Type & {
  context : CollectionContext
}

class Collection <
Type extends BaseComponentType = BaseComponentType
> extends InternalRouter <CollectionType<Type>> {
  collectionId: string

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      nextCategories: ['.next-categories', NextCategories],
      subheader: ['.subheader', Subheader],
      filters: ['.filters', Filters]
    }
  }

  getDefaultPageClass () {
    return CollectionInner
  }

  constructor (...args: ConstructorParameters<typeof InternalRouter>) {
    super(...args)
    this.collectionId = this.el.getAttribute('data-collection') || ''
    this.context = createCollectionContext({
      filterOpened: false
    })
  }

  initialized (): void {
    store.view.listenAndStart(this.switchView)
    super.initialized()
  }

  shouldRouteInternally (el: HTMLElement, pathName: string): boolean {
    return el?.getAttribute('data-collection') === this.collectionId
  }

  async internalRouting (...args: Parameters<InternalRouter['internalRouting']>) {
    super.internalRouting(...args)
  }

  switchView = (view: 'grid4' | 'grid3') => {
    this.el.classList.toggle('grid3', view === 'grid3')
  }
}

Collection.pageName = 'collection'

export default Collection
