import Component from 'navigation/component/Component'
import './Menu.scss'
import store from 'stores'
import { bindMethod } from 'helpers/bind'
import { ModulesMappping } from 'core/modulesMap'
import scroll from 'core/scroll'

import MenuItem from './menu-item/MenuItem'
import MenuSearch from './menu-search/MenuSearch'

type MenuType = {
  refs: {
    menuOverlay: HTMLElement
    menuFooter: HTMLElement
  }
};

class Menu extends Component<MenuType> {
  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    store.menu.toggleListener(add, this.toggleMenu)
    this.refs.menuOverlay[method]('click', this.closeMenu)
  }

  getModulesMap (): ModulesMappping {
    return {
      item: ['.menu-item', MenuItem],
      menuSearch: ['.menu-search', MenuSearch]
    }
  }

  closeMenu = () => {
    store.menu.set(null)
    scroll.unlock()
  }

  toggleMenu = (id: string | null | boolean) => {
    if (!id) store.search.set(null)
    id ? scroll.lock() : scroll.unlock()

    if (id === 'search') this.refs.menuFooter?.classList.add('hide')
    else this.refs.menuFooter?.classList.remove('hide')
  }
}

export default Menu
