import Component from 'navigation/component/Component'
import './CartItem.scss'
import { bindMethod } from 'helpers/bind'
import cart from 'stores/cart'
import { ModulesMappping } from 'core/modulesMap'
import SelectNative from 'components/select-native/SelectNative'
import { StoreType } from 'helpers/state'

import { LocalCartContext } from '../Cart'

type CartItemType = {
  refs: {
    deleteItem: HTMLButtonElement
    select: HTMLSelectElement[]
  },
  options: {
    section?: string
    updateCart?: (content: string) => void
  }
  context: StoreType<LocalCartContext>
}

class CartItem extends Component<CartItemType> {
  declare itemId: string

  initialized (): void {
    this.bindModules()
    this.itemId = this.el.dataset.itemId || ''
    super.initialized()
  }

  getModulesMap (): ModulesMappping {
    return {
      sizeSelect: ['.cart-item__size-select', SelectNative],
      qtySelect: ['.cart-item__qty-select', SelectNative]
    }
  }

  bindEvents (add: boolean) {
    const method = bindMethod(add)
    this.refs.deleteItem[method]('click', this.onDelete)
    const [selectSize, selectQty] = this.refs.select
    selectSize[method]('change', this.onSizeUpdate)
    selectQty[method]('change', this.onQuantityUpdate)
  }

  getSections = () => this.options.section ? [this.options.section] : []

  getUpdateParams = () => {
    const [selectSize, selectQty] = this.refs.select

    return [
      this.itemId as string,
      selectSize.value as string,
      +selectQty.value,
      this.getSections()
    ]as const
  }

  onSizeUpdate = async (value: any) => {
    this.context?.loading?.set(true)
    const result = await cart.updateVariant(...this.getUpdateParams())
    this.updateParent(result.sections)
    this.context?.loading?.set(false)
  }

  onQuantityUpdate = async (value: any) => {
    this.context?.loading?.set(true)
    const params = this.getUpdateParams().slice(1) as [string, number, string[]]
    const result = await cart.updateQuantity(...params)
    this.updateParent(result.sections)
    this.context?.loading?.set(false)
  }

  onDelete = async () => {
    this.context?.loading?.set(true)
    const result = await cart.deleteFromCart(this.itemId, this.getSections())
    this.updateParent(result.sections)
    this.context?.loading?.set(false)
  }

  updateParent = (sections: Record<string, string>) => {
    if (this.options.updateCart && this.options.section) {
      const content = sections[this.options.section]
      if (content) this.options.updateCart(content)
    }
  }
}

export default CartItem
