import { defer } from 'lodash-es'
import store from 'stores'
import Component from 'navigation/component/Component'
import router from 'core/router'
import resize, { dimensions } from 'helpers/resize'
import PageManager from 'navigation/page-manager/MainPageManager'
import MainPage from 'navigation/pages/MainPage'
import { ModulesMappping } from 'core/modulesMap'
import scroll from 'core/scroll'
import { bindMethod } from 'helpers/bind'
import './App.scss'
import Header from 'components/header/Header'
import Menu from 'components/menu/Menu'
import Panel from 'components/panel/Panel'
import Ticker from 'components/ticker/Ticker'
import Overlay from 'components/overlay/Overlay'
import Toaster from 'components/toaster/Toaster'
import config from 'core/config'

type AppType = {
  refs: {
    main: HTMLElement
    gridHelper: HTMLElement
  }
  modules: {
    pageManager: PageManager
  }
}

class App extends Component<AppType> {
  getModulesMap (): ModulesMappping {
    return {
      header: ['.header', Header],
      ticker: ['.ticker', Ticker],
      menu: ['.menu', Menu],
      overlay: ['.overlay', Overlay],
      panel: ['.panel', Panel],
      toaster: ['.toaster', Toaster]
    }
  }

  constructor (el: HTMLElement) {
    super(el)
    scroll.init()

    resize.setRoot(this.refs.main)
    resize.add(this)

    router.resolve()
    if (process.env.NODE_ENV === 'development')
      this.debug()

    // setTimeout(() => {
    //   resize.clear()
    //   resize.resize()
    // }, 100)
  }

  bindModules (): void {
    const pageManager = new PageManager(this.refs.main, '.page', MainPage)
    pageManager.once('show', this.show, this)

    super.bindModules()

    store.routers.get().main = pageManager
    this.modules.pageManager = pageManager
  }

  show () {
    document.documentElement.classList.add('js')
    document.documentElement.classList.remove('no-js')
    this.invoke('show')
  }

  bindEvents () {
    resize.add(this)

    scroll.on(this.scroll)
    store.loading.listenAndStart(this.onLoading)

    window.addEventListener('hashchange', this.onHashChange, false)
    this.onHashChange()

    this.resize()
    this.scroll()
  }

  onHashChange = () => {
    const { hash } = window.location
    if (hash === '#newsletter')
      store.panel.set(config.routes.newsletterPanel)
  }

  onLoading = (loading: boolean) => {
    document.documentElement.classList.toggle('loading', loading)
  }

  resize = () => {
    defer(() => {
      super.resize()
    })
    this.updateCSSVars()
  }

  updateCSSVars = () => {
    document.documentElement.style.setProperty('--vh', `${dimensions().height}px`)
    document.documentElement.style.setProperty('--ivh', `${dimensions().innerHeight}px`)
    document.documentElement.style.setProperty('--vw', `${dimensions().width}px`)
  }

  scroll = () => {
  }

  debug = () => {
    const method = bindMethod(true)
    window[method]('keypress', (e: Event) => {
      if ((e as KeyboardEvent).code === 'KeyG')
        this.refs.gridHelper.classList.toggle('visible')
    })
  }
}

export default App
