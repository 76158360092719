import anime, { AnimeInstance } from 'animejs'
import './ProductCard.scss'
import { ModulesMappping } from 'core/modulesMap'
import { bindMethod } from 'helpers/bind'
import Component from 'navigation/component/Component'

type ProductCardType = {
  modules: {
  }
  refs: {
    previousImage: HTMLButtonElement
    nextImage: HTMLButtonElement
    slider: HTMLDivElement
    tracks: HTMLDivElement[]
  }
}

class ProductCard extends Component<ProductCardType> {
  private _currentImage: number = 0
  private fraction: number = 0
  private animation: AnimeInstance | null = null
  private _switching: boolean = false
  private count: number = 0

  initialized (): void {
    this.currentImage = 0
    this.count = this.refs.slider.dataset.count ? parseInt(this.refs.slider.dataset.count) : 0
    this.setActiveTrack(0)
    super.initialized()
    this.resize()
  }

  get currentImage () {
    return this._currentImage
  }

  set currentImage (value) {
    this._currentImage = value

    if (this.refs.previousImage)
      this.refs.previousImage.classList.toggle('disabled', value === 0)

    if (this.refs.nextImage)
      this.refs.nextImage.classList.toggle('disabled', value === this.count - 1)
  }

  get switching () {
    return this._switching
  }

  set switching (value) {
    this._switching = value

    this.refs.slider.classList.toggle('switching', value)
  }

  bindEvents = (add = true): void => {
    const method = bindMethod(add)
    this.refs.slider[method]('scroll', this.onScroll)
    if (this.refs.previousImage && this.refs.nextImage) {
      this.refs.previousImage[method]('click', (e) => this.navigateImage(e, -1))
      this.refs.nextImage[method]('click', (e) => this.navigateImage(e, 1))
    }
  }

  setActiveTrack = (active: number) => {
    if (!this.refs.tracks) return
    this.refs.tracks.forEach((track: HTMLElement, index: number) => {
      if (index === active)
        track.classList.add('active')
      else
        track.classList.remove('active')
    })
  }

  onScroll = (e:Event) => {
    // if (this.switching) return
    const active = Math.round(this.refs.slider.scrollLeft / this.fraction)

    this.setActiveTrack(active)
  }

  resize (): void {
    if (this.refs.slider) this.fraction = this.refs.slider.scrollWidth / this.count
    super.resize()
  }

  navigateImage = async (event:Event, direction: number) => {
    event.preventDefault()
    event.stopPropagation()
    const nextImageIndex = this.currentImage + direction
    if (nextImageIndex < 0 || nextImageIndex >= this.count) return

    const options = { scrollLeft: this.refs.slider.scrollLeft }
    this.switching = true

    if (this.animation) this.animation.pause()
    this.animation = anime({
      targets: options,
      scrollLeft: nextImageIndex * this.fraction,
      easing: 'easeInOutQuad',
      update: () => {
        this.refs.slider.scrollLeft = options.scrollLeft
      },
      duration: 300
    })

    this.animation.finished.then(() => {
      this.switching = false
    })
    this.currentImage = nextImageIndex
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap()
    }
  }
}

export default ProductCard
