import { ModulesMappping } from 'core/modulesMap'
import './CartSuggestions.scss'
import ProductsRelated from 'pages/product/products-related/ProductsRelated'
import Component from 'navigation/component/Component'

export default class CartSuggestions extends Component {
  getModulesMap (): ModulesMappping {
    return {
      productsRelated: ['.products-related', ProductsRelated]
    }
  }
}
