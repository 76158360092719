import Component from 'navigation/component/Component'
import './Ticker.scss'
import { bindEmitterMethod, bindMethod } from 'helpers/bind'
import scroll from 'core/scroll'
import { clamp } from 'lodash-es'
import anime from 'animejs'
import { ModulesMappping } from 'core/modulesMap'
import mqStore from 'stores/mqStore'
import InfiniteCarousel from 'components/infinite/InfiniteCarousel'

type TickerType = {
  refs: {
    close: HTMLElement
  }
}
export default class Ticker extends Component<TickerType> {
  private height: number = 0
  initialized () {
    super.initialized()
    this.setLinksAttributes()
    this.resize()
    this.onScroll()
  }

  getModulesMap (): ModulesMappping {
    if (mqStore.tabletPortrait.get()) {
      return {
        infiniteTicker: ['.ticker__inner-text', InfiniteCarousel]
      }
    } else {
      return {}
    }
  }

  setLinksAttributes (): void {
    const links = this.el.querySelectorAll('a')
    links.forEach((link) => {
      if (link.href.includes('#')) link.role = 'button'
    })
  }

  bindEvents (add = true): void {
    const emitterMethod = bindEmitterMethod(add)
    const method = bindMethod(add)
    scroll[emitterMethod](() => this.onScroll())
    this.refs.close[method]('click', () => this.close())
    mqStore.tabletPortrait.toggleListener(add, () => this.onMqChange())
  }

  close = () => {
    anime({
      targets: this.el,
      height: 0,
      update: () => {
        this.resize()
        this.onScroll()
      }
    })
  }

  onScroll (): void {
    document.documentElement.style.setProperty('--header-top', clamp(this.height - scroll.scrollTop(), 0, this.height) + 'px')
  }

  onMqChange = () => {
    this.bindModules()
  }

  resize (): void {
    document.documentElement.style.setProperty('--ticker-height', `${this.el.offsetHeight}px`)
    this.height = this.el.offsetHeight
  }
}
