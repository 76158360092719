import 'styles/globals.scss'

import 'core/pageMap'
import 'helpers/detect'

import resize from 'helpers/resize'

import App from '../App'

resize.setRoot(document.body)

window.addEventListener('load', () => new App(document.documentElement as HTMLElement))
