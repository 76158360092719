import Component from 'navigation/component/Component'
import './CartReassurance.scss'
import anime from 'animejs'
import math from 'helpers/math'
import { bindMethod } from 'helpers/bind'

type CartReassuranceType = {
  refs: {
    cartReassuranceItems: HTMLElement[]
    cartReassurancePaginationItems: HTMLElement[]
  }
}

class CartReassurance extends Component<CartReassuranceType> {
  private previous = 0
  private _current = 0
  private next = 0

  private interval: NodeJS.Timeout | null = null

  get current (): number {
    return this._current
  }

  set current (value: number) {
    this.previous = this._current
    this._current = value % this.refs.cartReassuranceItems.length
    this.next = (value + 1) % this.refs.cartReassuranceItems.length

    this.refs.cartReassurancePaginationItems.forEach((item, index) => {
      item.classList.toggle('active', index === this._current)
    })
  }

  initialized (): void {
    super.initialized()
    this.toggleItems()
    this.refs.cartReassuranceItems[0].style.opacity = '1'
    this.refs.cartReassurancePaginationItems[0].classList.add('active')
  }

  bindEvents (add?: boolean): void {
    super.bindEvents(add)
    const method = bindMethod(add)
    this.refs.cartReassurancePaginationItems.forEach((item, index) => {
      item[method]('click', () => this.switchToItem(index))
    })
  }

  toggleItems (): void {
    this.interval = setInterval(() => {
      this.current++
      anime({
        targets: this.refs.cartReassuranceItems[this.previous],
        opacity: {
          value: [1, 0],
          duration: 100
        },
        translateX: [0, '-100'],
        duration: 300,
        easing: 'linear'
      })

      anime({
        targets: this.refs.cartReassuranceItems[this.current],
        opacity: {
          value: [0, 1],
          duration: 100
        },
        translateX: ['100', 0],
        duration: 300,
        easing: 'linear'
      })
    }, 5000)
  }

  switchToItem = (index: number): void => {
    if (this.current === index) return
    if (this.interval)
      clearInterval(this.interval)

    this.current = index
    anime({
      targets: this.refs.cartReassuranceItems[this.previous],
      opacity: {
        value: 0,
        duration: 100,
        easing: 'linear'

      },
      translateX: [0, this.previous > this.current ? '100' : '-100'],
      duration: 300,
      easing: 'easeOutCubic'
    })

    anime({
      targets: this.refs.cartReassuranceItems[this.current],
      opacity: {
        value: [0, 1],
        duration: 100,
        easing: 'linear'
      },
      translateX: [this.previous > this.current ? '-100' : '100', 0],
      duration: 300,
      easing: 'easeOutCubic',
      complete: () => {
        this.toggleItems()
      }
    })
  }
}

export default CartReassurance
