import Component from 'navigation/component/Component'
import './ProductInfos.scss'
import { bindMethod } from 'helpers/bind'
import { ProductContext } from 'stores/productStore'
import { ModulesMappping } from 'core/modulesMap'

type ProductInfosType = {
  refs: {
    addToCartButton: HTMLElement
  },
  options: {
    addToCart: () => Promise<void>
  },
  context: ProductContext
}

class ProductInfos extends Component<ProductInfosType> {
  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    if (this.refs.addToCartButton) this.refs.addToCartButton[method]('click', this.handleAddToCart)
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap()
    }
  }

  handleAddToCart = async (): Promise<void> => {
    this.refs.addToCartButton.classList.add('loading')
    await this.options.addToCart?.()
    this.refs.addToCartButton.classList.remove('loading')
  }

  refreshContent (el: HTMLElement) {
    const elementToReplace = ['.product-sizes', '.product-infos__button']
    elementToReplace.forEach((selector) => {
      const newElement = el.querySelector(selector)
      const oldElement = this.el.querySelector(selector)

      if (newElement && oldElement)
        oldElement.replaceWith(newElement)
    })

    this.refresh()
  }
}

export default ProductInfos
