import Component from 'navigation/component/Component'
import './WishlistItems.scss'
import persistentStore from 'stores/persistentStore'
import { getItems } from 'actions/wishlistActions'
import promise from 'helpers/promise'
import cart from 'stores/cart'
import { ModulesMappping } from 'core/modulesMap'

import WishlistItem from '../wishlist-item/WishlistItem'

type WishlistItemsType = {
  refs: {}
}

class WishlistItems extends Component<WishlistItemsType> {
  async initialized (): Promise<void> {
    this.el.classList.add('loading')
    const itemsExist = persistentStore.wishlist.get().length

    if (itemsExist) {
      this.el.innerHTML += await getItems()
      await promise.wait(500)
    }
    this.el.classList.remove('loading')

    this.bindModules()
    this.bindEvents()
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      item: ['.wishlist-item', WishlistItem]
    }
  }

  async bindEvents (add = true): Promise<void> {
    cart.loading.toggleListener(add, this.toggleLoading)
  }

  toggleLoading = (loading: boolean) => {
    this.el.classList.toggle('loading', loading)
  }
}

export default WishlistItems
