import Component from 'navigation/component/Component'
import './MenuItem.scss'
import store from 'stores'
import { bindMethod } from 'helpers/bind'

type MenuType = {
  refs: {
    menuToggles: HTMLElement[]
    menuLists: HTMLElement[]
  }
};

class MenuItem extends Component<MenuType> {
  private _padding = 3

  initialized (): void {
    super.initialized()

    const firstToggle = this.refs.menuToggles?.[0]
    firstToggle && this.toggleAccordion({
      currentTarget: firstToggle
    } as any)
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    store.menu.toggleListener(add, this.toggleMenu)
    this.refs?.menuToggles?.forEach?.(a => a[method]('click', this.toggleAccordion))
  }

  toggleAccordion = (event: Event) => {
    this.refs?.menuToggles?.forEach(toggle => {
      toggle.parentElement?.classList.toggle('active', toggle === event.currentTarget)
      toggle.parentElement?.classList.toggle('inactive', toggle !== event.currentTarget)
    })
  }

  closeMenu = () => {
    store.menu.set(null)
  }

  toggleMenu = (id: string | null | boolean) => {
    this.el?.classList.toggle('opened', id === this.el.dataset.id)
  }

  resize = () => {
    this.refs.menuLists?.forEach(list => {
      list.parentElement?.style.setProperty('--height', `${list.scrollHeight + this._padding}px`)
    })
  }
}

export default MenuItem
