import { IPageClass } from 'navigation/pages/Page'
import BackInStockPanel from 'pages/back-in-stock/BackInStockPanel'
import Cart from 'pages/cart/Cart'
import CartPanel from 'pages/cart/cart-panel/CartPanel'
import Collection from 'pages/collection/Collection'
import Home from 'pages/home/Home'
import MarketSelector from 'pages/market-selector/MarketSelector'
import NewsletterPanel from 'pages/newsletter/NewsletterPanel'
import Page404 from 'pages/page404/Page404'
import Product from 'pages/product/Product'
import Search from 'pages/search/Search'
import SizeGuidePanel from 'pages/size-guide/SizeGuidePanel'
import WishlistPanel from 'pages/wishlist/wishlist-panel/WishlistPanel'

const pageMap = {
  collection: Collection,
  product: Product,
  index: Home,
  search: Search,
  cart: Cart,
  'cart-panel': CartPanel,
  'wishlist-panel': WishlistPanel,
  'market-selector': MarketSelector,
  'size-guide-panel': SizeGuidePanel,
  'newsletter-panel': NewsletterPanel,
  'back-in-stock-panel': BackInStockPanel,
  page404: Page404 as any
} as Record<string, IPageClass>

export default pageMap
