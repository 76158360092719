import Component from 'navigation/component/Component'
import './MenuSearch.scss'
import { bindMethod } from 'helpers/bind'
import VirtualPageManager from 'navigation/page-manager/VirtualPageManager'
import store from 'stores'
import { debounce } from 'lodash-es'
import { ModulesMappping } from 'core/modulesMap'
import SearchSuggestions from 'components/search-suggestions/SearchSuggestions'
import SearchPage from 'navigation/pages/SearchPage'

type MenuSearchType = {
  refs : {
    menuSearch: HTMLInputElement
    searchPanel: HTMLElement
    results: HTMLElement
    searchClose: HTMLElement
    resultsCount: HTMLElement
    searchReset: HTMLElement
    searchSuggestions: HTMLElement
  }
}

class MenuSearch extends Component<MenuSearchType> {
  declare private pageManager: VirtualPageManager
  declare private searchUrl: string

  initialized (): void {
    this.searchUrl = this.el.dataset.searchUrl || ''
    super.initialized()
  }

  getModulesMap (): ModulesMappping {
    return {
      searchSuggestions: ['.search-suggestions', SearchSuggestions]
    }
  }

  bindModules (): void {
    super.bindModules()
    this.pageManager = new VirtualPageManager(store.search, this.refs.searchPanel, '.panel-search', SearchPage)
    store.routers.get().search = this.pageManager
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    this.refs.menuSearch?.[method]('input', this.onInput)
    this.refs.searchReset?.[method]('click', this.onClose)
    store.search.listenAndStart(this.onSearchPanelUpdate)
    store.menu.toggleListener(add, this.onMenuUpdate)
    this.pageManager.on('loading', this.onLoading)
    this.pageManager.on('empty', this.onEmpty)
    this.pageManager.on('loaded', this.onLoaded)
  }

  onSearchPanelUpdate = (url: any) => {
    // if (!url)
    //   this.resetStatus()
  }

  onMenuUpdate = (id: string | null | boolean) => {
    if (id !== 'search')
      this.onClose()
  }

  onEmpty = () => {
    this.updateCount(0)
    this.el.classList.remove('loading')
  }

  onClose = () => {
    // this.el.classList.remove('visible')
    store.search.set(null, true)
    this.resetStatus()
  }

  onLoading = () => {
    this.el.classList.add('loading')
    // this.refs.resultsCount.innerHTML = 'No'
  }

  onLoaded = (pageEl: HTMLElement) => {
    this.el.classList.remove('loading')
    // this.el.classList.add('visible')
    const count = pageEl?.dataset.searchCount || ''
    const nbCount = Number(count)
    this.updateCount(nbCount)
  }

  updateCount = (count: number) => {
    this.refs.searchSuggestions.classList.add('hide')
    this.refs.results.classList.add('active')
    if (count === 0)
      this.refs.resultsCount.innerHTML = 'No'
    else
      this.refs.resultsCount.innerHTML = count

    // this.el.classList.remove('visible')
  }

  resetStatus = () => {
    this._term = ''
    this.refs.menuSearch.value = ''
    this.refs.searchSuggestions.classList.remove('hide')
    this.refs.results.classList.remove('active')
  }

  declare private _term: string

  onInput = (e: Event) => {
    this._term = (e.target as HTMLInputElement).value

    if (!this._term) {
      store.search.set(null, true)
      this.resetStatus()
    } else { this.search(this._term) }
  }

  search = debounce((term: string) => {
    if (term !== this._term) return
    const separator = this.searchUrl.includes('?') ? '&' : '?'
    store.search.set(this.searchUrl + separator + 'q=' + term)
  }, 200)

  flush (): void {
    super.flush()
    this.onClose()
  }
}

export default MenuSearch
