import { IComponentClass, ModulesMappping } from 'core/modulesMap'
import './SizeGuidePanel.scss'
import PanelPage from 'navigation/pages/PanelPage'

import SizeGuideTables from './size-guide-tables/SizeGuideTables'
import SizeGuideSchema from './size-guide-schema/SizeGuideSchema'

type SizeGuidePanelType = {
  refs: {

  }
}

const shopifySection = 'panel-size-guide'

class SizeGuidePanel extends PanelPage<SizeGuidePanelType> {
  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      tables: ['.size-guide-tables', SizeGuideTables],
      schema: ['.size-guide-schema', SizeGuideSchema]
    }
  }

  getModuleParams (el: HTMLElement, componentConstructor: IComponentClass): Record<string, any> {
    return {
      ...super.getModuleParams(el, componentConstructor),
      section: shopifySection
    }
  }
}

export default SizeGuidePanel
