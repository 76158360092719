import config from 'core/config'
import { query } from 'data/query'
import store from 'stores'
import { cart } from 'stores/cart'

type AddToCartType = {id:string, quantity?:number, sections?:string[], getCart?:boolean, toast?:boolean, ghostSrc?:string}
type CartUpdateResponse = {
  sections : Record<string, string>
}

export type CartActions = {
  addToCart: (options:AddToCartType) => Promise<CartUpdateResponse>
  deleteFromCart: (variantId:string, sections?: string[]) => Promise<CartUpdateResponse>
  updateQuantity: (variantId:string, quantity:number, sections?: string[]) => Promise<CartUpdateResponse>
  updateVariant: (oldVariant:string, newVariant:string, quantity:number, sections?: string[]) => Promise<CartUpdateResponse>
}

const createCartAction = (cart: cart) : CartActions => {
  const addToCart = async ({
    id,
    quantity = 1,
    sections,
    getCart = true,
    toast = true
  }: AddToCartType) => {
    store.loading.set(true)
    try {
      const result = await query(config.api.cart.add + '.js', {
        body: {
          items: [{ id, quantity }],
          sections
        }
      })

      if (toast) {
        const w = window as any
        const item = result.items[0]
        const size = item.options_with_values.find((option: any) => option.name === 'Size' || option.name === 'Taille')
        const title = size ? `${size.name} ${size.value}` : item.product_title
        store.toaster.set(
          {
            type: 'cart',
            title: `<span>${title}</span> ${w.variantStrings.cart.added}`
            // externalLink: {
            //   href: '/checkout',
            //   label: w.variantStrings.cart.checkout
            // }

          }
        )
      }

      if (getCart)
        await get()
      else
        store.loading.set(false)

      return result
    } catch (error: any) {
      store.toaster.set({
        title: error.description || error.message || error
      })
      store.loading.set(false)
      cart.loading.set(false)
    }
  }
  const deleteFromCart = async (id:string, sections = []) => {
    try {
      const result = await query(config.api.cart.change + '.js', {
        body: {
          quantity: 0,
          id,
          sections
        }
      })
      store.loading.set(false)
      return result
    } catch (error:any) {
      store.toaster.set({
        title: error.description || error.message || error
      })
      store.loading.set(false)
      cart.loading.set(false)
    }
  }
  const updateQuantity = async (id:string, quantity:number, sections = []) => {
    try {
      const result = await query(config.api.cart.change + '.js', {
        body: {
          id,
          quantity,
          sections
        }
      })

      await get()
      cart.loading.set(false)

      return result
    } catch (error: any) {
      store.toaster.set({
        title: error.description || error.message || error
      })
      store.loading.set(false)
      cart.loading.set(false)
    }
  }
  const updateVariant = async (oldVariant:string, newVariant:string, quantity:number, sections = []) => {
    try {
      await deleteFromCart(oldVariant, sections)

      const result = await addToCart({
        id: newVariant,
        quantity,
        sections,
        getCart: false,
        toast: false,
        ghostSrc: ''
      })

      store.loading.set(false)
      cart.loading.set(false)

      return result
    } catch (error: any) {
      store.toaster.set({
        title: error.description || error.message || error
      })
      store.loading.set(false)
      cart.loading.set(false)
    }
  }
  const get = async () => {
    try {
      const result = await query(config.api.cart.get + '.js')
      cart.count.set(result.item_count)
      store.loading.set(false)
      cart.loading.set(false)
    } catch (error: any) {
      store.toaster.set({
        title: error.description || error.message || error
      })
      store.loading.set(false)
      cart.loading.set(false)
    }
  }

  return {
    addToCart,
    deleteFromCart,
    updateQuantity,
    updateVariant
  }
}

export { createCartAction }
