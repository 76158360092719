import { IComponentClass } from 'core/modulesMap'
import './MarketSelector.scss'
import PanelPage from 'navigation/pages/PanelPage'
import { bindEmitterMethod, bindMethod } from 'helpers/bind'

type MarketSelectorType = {
  refs: {
    select: HTMLSelectElement[]
    language: HTMLSelectElement
    country: HTMLSelectElement
    sizeSelect: HTMLSelectElement
    applyButton: HTMLElement
    form: HTMLFormElement
  }
}

const shopifySection = 'market-selector'

class MarketSelector extends PanelPage<MarketSelectorType> {
  getModuleParams (el: HTMLElement, componentConstructor: IComponentClass): Record<string, any> {
    return {
      ...super.getModuleParams(el, componentConstructor),
      section: shopifySection
    }
  }

  bindEvents = (add = true): void => {
    super.bindEvents(add)
    const method = bindMethod(add)

    const [selectCountry, selectLanguage] = this.refs.select
    selectCountry[method]('change', this.onSelectCountry)
    selectLanguage[method]('change', this.onSelectLanguage)
    this.refs.applyButton[method]('click', this.onSubmit)
  }

  onSubmit = (e: any) => {
    e.preventDefault()
    this.refs.applyButton.classList.add('loading')
    this.refs.form.submit()
  }

  onSelectLanguage = (e: any) => {
    const select = e.target?.selectedOptions?.[0]
    if (!select) return
    this.refs.language.value = select.dataset.language!
  }

  onSelectCountry = (e: any) => {
    const select = e.target?.selectedOptions?.[0]
    if (!select) return
    this.refs.country.value = select.dataset.country!
  }
}

export default MarketSelector
