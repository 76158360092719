import PageManager from './PageManager'

const forceTracking = (page: Document) => {
  if (page.body === document.body) return

  const scriptToReplace = [
    'script#shop-js-features',
    'script#shop-js-analytics'
  ]

  scriptToReplace.forEach((script) => {
    const currentScript = document.querySelector(script)
    const newScript = page.querySelector(script)

    if (currentScript && newScript && currentScript !== newScript) {
      const script = document.createElement('script')
      script.innerHTML = newScript.innerHTML

      Array.from(newScript.attributes).forEach((attr: any) => {
        script.setAttribute(attr.name, attr.value)
      })

      currentScript.parentNode?.insertBefore(script, currentScript)
      currentScript.remove()
    }
  })

  const content = page.head.innerHTML || ''

  const track = [
    ...(content.match(/window.ShopifyAnalytics.lib.page\((.*?)\)/g) || []) as string[],
    ...(content.match(/window.ShopifyAnalytics.lib.track\((.*?)\)/g) || []) as string[],
    ...(content.match(/webPixelsManagerAPI.publish\((.*?)\)/g) as string[] || [])?.map(s => {
      return s.replace('webPixelsManagerAPI', 'window.Shopify.analytics')
    })
  ]?.filter(Boolean).join('\n')

  const script = document.createElement('script')
  script.innerHTML = `
      try {
        ${track}
      } catch (e) {
        console.error(e)
      }
    `
  document.head.appendChild(script)

  setTimeout(() => {
    script.remove()
  }, 100)
}

class CustomPageManager extends PageManager {
  async initializePage () {
    super.initializePage()
  }

  extractPageFromXHR (root: Parameters<PageManager['extractPageFromXHR']>[0]) {
    return super.extractPageFromXHR(root)
  }

  extractPageInfo (page: Parameters<PageManager['extractPageInfo']>[0]) {
    try {
      forceTracking(page)
    } catch (e) {
      console.error(e) // eslint-disable-line
    }

    return super.extractPageInfo(page)
  }
}

export { forceTracking }

export default CustomPageManager
