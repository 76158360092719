import { IComponentClass, ModulesMappping } from 'core/modulesMap'
import PanelPage from 'navigation/pages/PanelPage'
import { StoreType } from 'helpers/state'
import { bindMethod } from 'helpers/bind'

import Cart, { LocalCartContext } from '../Cart'

import './CartPanel.scss'

type CartPanelType = {
  refs: {
    checkoutButton: HTMLButtonElement;
  }
  modules: {
  }
  context: StoreType<LocalCartContext>
}

const shopifySection = 'panel-cart'

class CartPanel extends PanelPage<CartPanelType> {
  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      ...Cart.prototype.getModulesMap()
    }
  }

  createContext () {
    return Cart.prototype.createContext()
  }

  async updateCart (content: string) {
    return Cart.prototype.updateCart.call(this, content)
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)

    this.context.loading.toggleListener(add, this.onLoadingUpdate)
    super.bindEvents(add)

    if (this.refs.checkoutButton) this.refs.checkoutButton[method]('click', this.onCheckout)
  }

  onLoadingUpdate = (loading: boolean) => {
    this.el.classList.toggle('cart-loading', loading)
  }

  getModuleParams (el: HTMLElement, componentConstructor: IComponentClass): Record<string, any> {
    return {
      ...super.getModuleParams(el, componentConstructor),
      section: shopifySection,
      updateCart: this.updateCart.bind(this)
    }
  }

  onCheckout = () => {
    this.refs.checkoutButton.classList.add('loading')
  }

  initialized (): void {
    super.initialized()
  }
}

export default CartPanel
