import { IComponentClass, ModulesMappping } from 'core/modulesMap'
import MainPage from 'navigation/pages/MainPage'
import createStore, { StoreType } from 'helpers/state'
import anime from 'animejs'

import CartFooter from './cart-footer/CartFooter'
import CartHeader from './cart-header/CartHeader'
import CartItem from './cart-item/CartItem'
import CartItems from './cart-items/CartItems'
import CartSuggestions from './cart-suggestions/CartSuggestions'
import CartReassurance from './cart-reassurance/CartReassurance'

import './Cart.scss'
export type LocalCartContext = {
  loading: boolean
}

type CartType = {
  refs: {
    cartItems: HTMLElement
  }
  context: StoreType<LocalCartContext>
}

const shopifySection = 'main-cart'

class Cart extends MainPage<CartType> {
  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      cartFooter: ['.cart-footer', CartFooter],
      cartHeader: ['.cart-header', CartHeader],
      cartItem: ['.cart-item', CartItem],
      cartItems: ['.cart-items', CartItems],
      cartSuggestions: ['.cart-suggestions', CartSuggestions],
      cartReassurance: ['.cart-reassurance', CartReassurance]
    }
  }

  getModuleParams (el: HTMLElement, componentConstructor: IComponentClass): Record<string, any> {
    return {
      ...super.getModuleParams(el, componentConstructor),
      section: shopifySection,
      updateCart: this.updateCart.bind(this)
    }
  }

  createContext () {
    return createStore<LocalCartContext>({ loading: false })
  }

  bindEvents (add: boolean): void {
    this.context.loading.toggleListener(add, this.onLoadingUpdate)
  }

  onLoadingUpdate = (loading: boolean) => {
    this.el.classList.toggle('cart-loading', loading)
  }

  async updateCart (content: string) {
    const newContent = document.createElement('div')

    newContent.innerHTML = content

    const oldEl = this.el
    this.el = oldEl.cloneNode(false) as HTMLElement
    this.el.innerHTML = newContent.firstElementChild?.innerHTML || ''
    oldEl.insertAdjacentElement('afterend', this.el)
    this.refresh()

    await Promise.all([
      anime({
        targets: oldEl,
        opacity: 0,
        easing: 'easeOutQuad',
        duration: 300
      }).finished,
      anime({
        targets: this.el,
        opacity: [0, 1],
        easing: 'easeOutQuad',
        duration: 300
      }).finished
    ])

    oldEl.remove()
  }

  initialized (): void {
    super.initialized()
  }
}

Cart.pageName = 'cart'

export default Cart
