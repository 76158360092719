import { defer } from 'lodash-es'
import anime from 'animejs'
import scroll from 'core/scroll'
import store from 'stores'
import { bindMethod } from 'helpers/bind'
import { BaseComponentType } from 'navigation/component/Component'

import Page, { IPage } from './Page'

import './PanelPage.scss'

export default class PanelPage<
  Type extends BaseComponentType = BaseComponentType
> extends Page<Type> implements IPage {
  async askPreload (previousPage?:string) {
    this.el.classList.add('appearing')
    await super.askPreload(previousPage)
    await this.resetScroll()
  }

  bindEvents (add = true) {
    const method = bindMethod(add)
    const refs = this.refs as any
    refs.panelClose?.[method]('click', this.onClose)
  }

  onClose = () => {
    store.panel.set(null)
  }

  async resetScroll () {
    if (this.pageManager?.container.scrollTop) await scroll.scrollTo(0, { target: this.pageManager.container, duration: 200 })
  }

  /* SHOW */
  show (previousPage: string) {
    this.resize()
    Page.prototype.show.call(this, previousPage)
    const isSamePage = previousPage === this.pageName() && previousPage !== PanelPage.pageName

    return anime(Object.assign((isSamePage
      ? {
          opacity: [0, 1],
          easing: 'easeOutCubic'
        }
      : {
          opacity: [0, 1],
          translateX: previousPage ? ['20%', 0] : null,
          easing: 'easeOutCubic'
        }),
    {
      targets: this.el,
      duration: 400,
      delay: previousPage ? 50 : 0,
      changeBegin: () => {
        this.invoke('show')
      },
      complete: () => {
        defer(() => this.resize())
      }
    })).finished
  }

  transitionComplete () {
    this.el.classList.remove('appearing')
    super.transitionComplete()
  }

  /* HIDE */

  hide (nextPage: string) {
    // this.el.classList.add('disappearing')
    // const isSamePage = nextPage === this.pageName() && nextPage !== PanelPage.pageName

    return anime(Object.assign(({
      opacity: [1, 0],
      translateX: nextPage ? [0, '-20%'] : null,
      easing: 'easeOutCubic'
    }),
    {
      targets: this.el,
      delay: nextPage ? 50 : 0,
      duration: 400
    })).finished
  }
}
