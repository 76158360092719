import { bindMethod } from 'helpers/bind'
import Component from 'navigation/component/Component'
import { addToWishlist, removeFromWishlist } from 'actions/wishlistActions'
import persistentStore from 'stores/persistentStore'

type WishlistFavButtonType = {
  refs: {
    wishlistButton: HTMLButtonElement
  }
}

class WishlistFavButton extends Component<WishlistFavButtonType> {
  private productUrl = ''

  initialized (): void {
    this.productUrl = this.el.dataset.wishlist?.split('?')?.[0] || ''
    super.initialized()
  }

  bindEvents = (add = true): void => {
    persistentStore.wishlist.listenAndStart(this.updateWishlist)
    const method = bindMethod(add)

    if (this.el) this.el[method]('click', this.onToggleWishlist)
  }

  updateWishlist = () : void => {
    const wishlist = persistentStore.wishlist.get()
    const included = wishlist.some(v => v.includes(this.productUrl))
    this.el.classList.toggle('wish', included)
  }

  onToggleWishlist = async (event:Event) => {
    event.preventDefault()
    event.stopPropagation()

    const wishlist = persistentStore.wishlist.get()
    const included = wishlist.some(v => v.includes(this.productUrl))
    if (included) removeFromWishlist(this.productUrl)
    else addToWishlist(this.productUrl, this.el.dataset.name || '', this.el.dataset.featured || '')
  }
}

export default WishlistFavButton
