import anime from 'animejs'
import Component from 'navigation/component/Component'
import { ModulesMappping } from 'core/modulesMap'

import ProductCard from '../product-card/ProductCard'
import './ProductGrid.scss'

class ProductGrid extends Component {
  private io: IntersectionObserver = null!
  private currentPage = 1
  private pageCount = 1
  private collectionRoute = ''
  private url = new URLSearchParams(window.location.search)
  private lastCard:HTMLElement = null!

  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  getModulesMap (): ModulesMappping {
    return {
      productCards: ['.product-card', ProductCard]
    }
  }

  bindModules (): void {
    super.bindModules()
  }

  initialized (): void {
    this.bindModules()
    this.collectionRoute = this.el.dataset.route || ''
    this.pageCount = parseInt(this.el.dataset.pages || '1')
    this.io = new IntersectionObserver(this.onEndOfPage)
    this.lastCard = this.el.querySelector('.product-card:last-child')!

    if (this.lastCard) this.io.observe(this.lastCard)
    const page = this.url.get('page')
    this.currentPage = page !== null ? +parseInt(page) : 1
    super.initialized()
  }

  onEndOfPage = (entries:IntersectionObserverEntry[]) => {
    if (entries[0].intersectionRatio > 0 && this.currentPage < this.pageCount)
      this.loadPage(this.currentPage + 1)
  }

  loadPage = async (page:number) => {
    let url = window.location as any
    if (window.location.search.length > 0)
      url += `&page=${page}`

    else
      url += `?page=${page}`

    const request = await fetch(url)
    const response = await request.text()
    const dom = new DOMParser().parseFromString(response, 'text/html')
    const products = dom.querySelector('.product-grid')!.children

    anime({
      targets: products,
      opacity: [0, 1],
      duration: 600,
      delay: anime.stagger(100),
      easing: 'easeInOutQuad'
    })
    this.el.append(...products)
    this.io.disconnect()
    this.lastCard = this.el.querySelector('.product-card:last-child')!

    this.io.observe(this.lastCard)
    this.currentPage++
    this.bindModules()
  }

  flush (): void {
    this.io.disconnect()
  }
}

export default ProductGrid
