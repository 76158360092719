import Component from 'navigation/component/Component'
import './NewsletterForm.scss'
import { bindMethod } from 'helpers/bind'
import { newNewsletterSubscription } from 'core/mail'
import store from 'stores'

type NewsletterFormType = {}

class NewsletterForm extends Component<NewsletterFormType> {
  bindEvents (add?: boolean): void {
    const method = bindMethod(add)
    this.el[method]('submit', (e) => this.onSubmit(e))
  }

  async onSubmit (event: Event): Promise<void> {
    event.preventDefault()
    const data = Object.fromEntries(new FormData(this.el as HTMLFormElement).entries()) as any
    await newNewsletterSubscription(data)
    store.panel.set(null)
  }
}

export default NewsletterForm
