import Component, { extractRefs } from 'navigation/component/Component'
import './Utils.scss'
import { bindMethod } from 'helpers/bind'
import anime from 'animejs'

type UtilsType = {
  refs: {
    utilsRuler: HTMLElement;
    utilsButtons: HTMLButtonElement[];
    utilsBlocks: HTMLElement;
    utilsElements: HTMLElement[];
    utilsPlaceholderParagraph: HTMLElement[];
    accordionElements: HTMLElement[];
    utilsParagraphs: HTMLElement[];
    utilsWrapper: HTMLElement;
  };
};

class Utils extends Component<UtilsType> {
  // private autoScrolling: boolean = false

  bindRefs (target?: HTMLElement): void {
    this.buildMenu()
    return super.bindRefs(target)
  }

  buildMenu (): void {
    const refs = extractRefs(this.el as HTMLElement)
    const elements = refs?.utilsPlaceholderParagraph as HTMLElement[]

    elements?.forEach((placeholder, index) => {
      const id = placeholder.dataset.block as string

      const paragraph = this.el.querySelector(`div[data-block="${id}"]`) as HTMLElement

      (Array.from(paragraph.querySelectorAll('h1, h2')) as HTMLElement[]).forEach((title, index) => {
        const button = document.createElement('button')

        button.classList.add('utils__nav-button')
        button.dataset.block = id + '-' + index
        button.dataset.refs = 'utilsButtons'
        button.textContent = title.textContent || ''

        title.dataset.block = id + '-' + index
        title.dataset.refs = 'utilsElements'

        placeholder.parentElement?.insertBefore(button, placeholder)
      })

      placeholder.remove()
      delete paragraph.dataset.block
    })
  }

  initialized (): void {
    super.initialized()
    this.handleScroll()
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)

    if (this.refs.utilsButtons) {
      this.refs.utilsButtons.forEach(button => {
        button[method]('click', this.handleTitleButtonClick)
      })
    }

    if (this.refs.accordionElements) {
      this.refs.accordionElements.forEach(element => {
        element[method]('click', this.handleAccordionClick)
      })
    }

    window[method]('scroll', this.handleScroll)
  }

  handleTitleButtonClick = (e: Event) => {
    const button = e.currentTarget as HTMLButtonElement
    const id = button.dataset.block as string

    const targetElement = this.refs.utilsElements.find(element => element.dataset.block === id)

    if (!targetElement) return

    const from = document.scrollingElement?.scrollTop || 0
    const target = {
      value: 0
    }

    anime({
      targets: target,
      value: 1,
      duration: 500,
      easing: 'easeInOutQuad',
      update: () => {
        if (!document.scrollingElement) return
        const top = targetElement.offsetTop + this.refs.utilsRuler.offsetHeight
        const elTop = top - from

        const scroll = Math.max(0, from + (elTop * target.value))
        document.scrollingElement.scrollTop = scroll
      }
    })
  }

  handleScroll = () => {
    const from = document.scrollingElement?.scrollTop || 0

    const current = this.refs.utilsElements?.find(element => {
      const nextElement = this.refs.utilsElements[this.refs.utilsElements.indexOf(element) + 1]
      const top = element.offsetTop + this.refs.utilsRuler.offsetHeight
      const padding = 20

      return nextElement ? (top + (nextElement ? nextElement.offsetTop - element.offsetTop : 0) - padding >= from) : true
    })?.dataset?.block

    if (!this.refs.utilsButtons) return

    this.refs.utilsButtons?.forEach((element, index) => {
      element.classList.toggle('current', element.dataset?.block === current)
    })
  }

  handleAccordionClick = (e: Event) => {
    const button = e.currentTarget as HTMLButtonElement
    const id = button.dataset.id as string

    const targetElement = this.refs.accordionElements.find(element => element.dataset.id === id)

    if (!targetElement) return

    targetElement.classList.toggle('active')
  }

  resize (): void {
    super.resize()
  }
}

export default Utils
