import { createStore } from 'helpers/state'

type SizeStore = {
  notificationHeight: number
  headerHeight: number
}

const sizeStore = createStore<SizeStore>({
  notificationHeight: 0,
  headerHeight: 0
})

export default sizeStore
