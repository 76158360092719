import './SubheaderUtilities.scss'
import Component from 'navigation/component/Component'
import { bindMethod } from 'helpers/bind'
import store from 'stores'

type SubheaderUtilitiesType = {
  refs: {
    grid4: HTMLButtonElement
    grid3: HTMLButtonElement
    filtersButton: HTMLButtonElement
  }
}

class SubheaderUtilities extends Component<SubheaderUtilitiesType> {
  initialized (): void {
    super.initialized()
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    this.refs.grid3[method]('click', () => this.onViewClick('grid3'))
    this.refs.grid4[method]('click', () => this.onViewClick('grid4'))
    this.refs.filtersButton[method]('click', () => this.onFiltersClick())
  }

  onViewClick (view: 'grid4' | 'grid3'): void {
    store.view.set(view)
  }

  onFiltersClick (): void {
    store.filtersOpened.set(true)
  }
}

export default SubheaderUtilities
