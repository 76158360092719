import Component from 'navigation/component/Component'
import { bindMethod } from 'helpers/bind'
import { ProductContext } from 'stores/productStore'
import './ProductMobileBar.scss'
import scroll from 'core/scroll'

type ProductMobileBarType = {
  refs: {
    addToCartMobileButton: HTMLButtonElement
    productMobileOverlay?: HTMLDivElement
    productMobileSizeButton?: HTMLButtonElement[]
    productMobileOOSButton?: HTMLButtonElement[]
  },
  options: {
    addToCart: () => Promise<void>
  },
  context: ProductContext
}

class ProductMobileBar extends Component<ProductMobileBarType> {
  bindEvents (add?: boolean): void {
    const method = bindMethod(add)
    this.refs.addToCartMobileButton[method]('click', this.onButtonClick)
    this.refs.productMobileOverlay?.[method]('click', this.close)
    this.refs.productMobileSizeButton?.forEach((button) => button[method]('click', this.selectSize))
    this.refs.productMobileOOSButton?.forEach((button) => button[method]('click', this.notifyMe))
  }

  open = (): void => {
    this.el.classList.add('open-size')
    scroll.lock()
  }

  close = (): void => {
    this.el.classList.remove('open-size')
    scroll.unlock()
  }

  notifyMe = (): void => {
  }

  selectSize = (event :Event): void => {
    const variantId = (event.currentTarget as HTMLButtonElement).dataset.variantId as string
    this.close()

    if (variantId) {
      this.context.variantId.set(variantId)
      this.addToCart()
    }
  }

  onButtonClick = (): void => {
    const oos = this.refs.addToCartMobileButton.getAttribute('data-oos')
    const onesize = this.refs.addToCartMobileButton.getAttribute('data-onesize')

    if (oos) this.notifyMe()
    else if (onesize) this.addToCart()
    else this.open()
  }

  addToCart = async () => {
    this.refs.addToCartMobileButton.classList.add('loading')
    await this.options.addToCart?.()
    this.refs.addToCartMobileButton.classList.remove('loading')
  }
}

export default ProductMobileBar
