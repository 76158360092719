import Component from 'navigation/component/Component'
import './Stockists.scss'
import { bindEmitterMethod, bindMethod } from 'helpers/bind'
import { ModulesMappping } from 'core/modulesMap'
import Map from 'components/map/Map'
import scroll from 'core/scroll'
import mqStore from 'stores/mqStore'

type StockistsType = {
  refs: {
    countrySelect: HTMLButtonElement
    countryElements: HTMLElement[]
    countryOptions: HTMLOptionElement[]
    citySelect: HTMLButtonElement
    cityElements: HTMLElement[]
    cityElementsName: HTMLElement[]
    cityElementsAddress: HTMLElement[]
    cityOptions: HTMLElement[]
    stockistsWrapper: HTMLElement
    stockistsList: HTMLElement
    stockistsMap: HTMLElement
    stockistsTabs: HTMLElement[]
    zoomIn: HTMLButtonElement
    zoomOut: HTMLButtonElement
  },
  modules: {
    map: Map
  }
}

class Stockists extends Component<StockistsType> {
  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
    this.handleTabClick({ currentTarget: this.refs.stockistsTabs[0] } as any)
    this.onPageShown()
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      map: ['.stockists__map-inner', Map]
    }
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    const emitterMethod = bindEmitterMethod(add)
    const methodSignal = add ? 'on' : 'off'
    this.refs.countrySelect[method]('change', this.handleCountrySelect)
    this.refs.citySelect[method]('change', this.handleCitySelect)
    this.refs.stockistsTabs.forEach(tab => {
      tab[method]('click', this.handleTabClick)
    })
    this.refs.cityElementsName.forEach((el, i) => {
      el[method]('click', () => this.cityElementNameClick(i))
    })
    this.refs.zoomOut[method]('click', this.modules.map.zoomOut)
    this.refs.zoomIn[method]('click', this.modules.map.zoomIn)
    this.modules.map[methodSignal]('clicked', this.onMapClicked)
    scroll[emitterMethod](() => this.onScroll())
  }

  onScroll (): void {
    this.modules.map.resize()
  }

  cityElementNameClick (i: number): void {
    if (mqStore.tabletPortrait.get()) return

    const cityElement = this.refs.cityElements[i]
    if (cityElement) {
      const coords = cityElement.getAttribute('data-coords')
      if (coords) {
        const [lng, lat] = coords.split(',').map(a => +a)
        this.modules.map.map.flyTo({
          center: [lng, lat],
          zoom: 10
        })
      }
    }
  }

  onMapClicked = (i: number) => {
    if (!this.refs.cityElements || !this.refs.cityElements[i] || mqStore.tabletPortrait.get()) return
    const scrollTop = this.refs.cityElements[i].offsetTop
    scroll.scrollTo(scrollTop)
  }

  setPoints (): void {
    const points = this.refs.cityElements.filter(el => !el.classList.contains('hidden')).filter(el => !el.parentElement?.parentElement?.classList.contains('hidden')).map((el, i) => {
      const coords = el.getAttribute('data-coords')
      const p = coords ? coords.split(',').map(a => +a) : []
      let text = this.refs.cityElementsName[i].innerHTML
      const address = this.refs.cityElementsAddress[i].innerHTML
      text += `<br>${address}`

      return [p, text]
    })

    this.modules.map.setMarkers(points)
  }

  selectLocalCountry (): void {
    const localCode = this.refs.countrySelect.dataset.local
    const localOption = this.refs.countryOptions.find(option => option.value === localCode)
    if (localOption) {
      localOption.setAttribute('selected', 'selected')
      this.handleCountrySelect({ currentTarget: localOption } as any)
    }
  }

  onPageShown (): void {
    this.modules.map.resize()
    this.selectLocalCountry()
    this.setPoints()
  }

  handleTabClick = (e: Event) => {
    const tab = e.currentTarget as HTMLButtonElement
    this.refs.stockistsTabs.forEach(element => {
      element.classList.remove('active')
    })

    tab.classList.add('active')

    this.refs.stockistsList.classList.toggle('is-hidden', tab.dataset.tab !== 'list')
    this.refs.stockistsMap.classList.toggle('is-hidden', tab.dataset.tab !== 'map')

    this.modules.map.resize()
  }

  handleCountrySelect = (e: Event) => {
    const select = e.currentTarget as HTMLButtonElement
    this.updateCountryStatus(select.value)

    this.refs.citySelect.removeAttribute('disabled')

    this.refs.cityOptions.forEach(option => {
      option.classList.add('option-hidden')
      if (option.dataset.country === select.value || select.value === 'all')
        option.classList.remove('option-hidden')

      option.removeAttribute('selected')
    })

    // select first city
    const firstCity = this.refs.cityOptions.find(option => !option.classList.contains('option-hidden'))
    firstCity?.setAttribute('selected', 'selected')

    // reset map points
    this.setPoints()
  }

  updateCountryStatus (status: string): void {
    this.refs.countryElements.forEach(element => {
      element.classList.add('hidden')

      if (element.dataset.country === status || status === 'all')
        element.classList.remove('hidden')
    })

    this.updateCityStatus('all')
  }

  handleCitySelect = (e: Event) => {
    const select = e.currentTarget as HTMLButtonElement
    this.updateCityStatus(select.value)

    // reset map points
    this.setPoints()
  }

  updateCityStatus (status: string): void {
    this.refs.cityElements.forEach(element => {
      element.classList.add('hidden')

      if (element.dataset.city === status || status === 'all')
        element.classList.remove('hidden')
    })
  }

  resize (): void {
    super.resize()
  }
}

export default Stockists
