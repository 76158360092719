import Component from 'navigation/component/Component'
import './SelectNative.scss'
import { bindMethod } from 'helpers/bind'

type SelectNativeType = {
  refs: {
    select: HTMLSelectElement
    toggle: HTMLButtonElement
  }
}
class SelectNative extends Component<SelectNativeType> {
  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
  }

  bindEvents (add = true): void {
    const method = bindMethod(add)
    this.refs.select[method]('change', this.onChange)
    // this.refs.toggle[method]('click', this.toggle)
  }

  value () {
    return this.refs.select.value
  }

  //   toggle = () => {
  //     this.refs.select.focus()
  //   }

  onChange = (event: Event) => {
    this.emit('change', (event.target as HTMLSelectElement).value)
  }
}

export default SelectNative
