import Component from 'navigation/component/Component'
import { bindEmitterMethod, bindMethod } from 'helpers/bind'
import router from 'core/router'
import { ModulesMappping } from 'core/modulesMap'
import InfiniteLine from 'components/infinite/InfiniteLine'
import detect from 'helpers/detect'
import browser from 'helpers/browser'
import Tempus from '@darkroom.engineering/tempus'
import scroll from 'core/scroll'
import './InfiniteCarousel.scss'

type InfiniteCarouselType = {
  refs: {}
  modules: {
    infiniteLine: InfiniteLine
  }
}

const decay = 0.2

type MouseInfo = {
  x: number
  y: number
  time: number
  start: number
} | null

const STATES = {
  INIT: 0,
  SCROLLING: 1,
  SWIPPING: 2
}
const getFrame = (event:any) => {
  if (event?.touches && event?.touches[0]) event = event.touches[0]
  if (event?.changedTouches && event?.changedTouches[0]) event = event.changedTouches[0]
  return { x: event.clientX, y: event.clientY, time: Date.now() }
}
const detectState = (frame:any, first:any) => {
  const distanceX = frame.x - first.x
  const distanceY = frame.y - first.y
  const angle = Math.atan2(distanceY, distanceX) * (180 / Math.PI)
  const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY)

  const range = 45
  const gap = Math.abs(90 - Math.abs(angle))
  const horizontal = gap >= 90 - range

  if (horizontal && distance > 20) return STATES.SWIPPING
  else if (!horizontal && distance > 50) return STATES.SCROLLING
  return STATES.INIT
}

class InfiniteCarousel extends Component<InfiniteCarouselType> {
  public x = 0

  public targetX = 0
  private mouse: MouseInfo = null
  private _enabled = true
  private _canDrag = true
  private _canClick = false

  get enabled (): boolean {
    return this._enabled
  }

  get originalSize () {
    return this.modules.infiniteLine?.originalSize || 0
  }

  get totalSize () {
    return this.modules.infiniteLine?.totalSize || 1
  }

  public set enabled (value: boolean) {
    this._enabled = value
  }

  get canDrag (): boolean {
    return this._canDrag
  }

  public set canDrag (value: boolean) {
    this._canDrag = value
    this.el.classList.toggle('drag-disabled', !value)
  }

  constructor (el: HTMLElement, options:any) {
    super(el)
    this.bindModules()
    this.el.classList.add('infinite-carousel')
  }

  getModulesMap (): ModulesMappping {
    return {
      infiniteLine: ['self', InfiniteLine]
    }
  }

  initialized (): void {
    super.initialized()
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    const emitterMethod = bindEmitterMethod(add)

    if (add) Tempus.add(this.onUpdate)
    else Tempus.remove(this.onUpdate)

    this.el?.[method]('wheel', this.onMouseWheel, { passive: true })
    this.el?.[method](detect.touch ? 'touchstart' : 'mousedown', this.onMouseDown)
    this.el?.[method](detect.touch ? 'touchmove' : 'mousemove', this.onMouseMove)
    window[method](detect.touch ? 'touchend' : 'mouseup', this.onMouseUp)
    this.el[method]('click', this.onClick)

    this.modules.infiniteLine[emitterMethod]('restructure', this.onRestructuration)
  }

  onRestructuration = (): void => {
    this.emit('restructure')
  }

  onClick = (e: Event): void => {
    if (e) e.preventDefault()
    if (!this._canClick) return

    const target = e.target as HTMLElement

    const position = this.modules.infiniteLine.items.indexOf(target) % this.modules.infiniteLine.originalItems.length
    if ((target as HTMLLinkElement).href) {
      router.navigate(target.getAttribute('href') || '')
      scroll.unlock(true)
    }
    if (target.tagName === 'VIDEO') return
    this.emit('click', position)
  }

  onMouseWheel = (e: Event): void => {
    const isDraggingHorizontally = Math.abs((e as WheelEvent).deltaX) > Math.abs((e as WheelEvent).deltaY)
    if (!isDraggingHorizontally) return
    this.targetX -= (e as WheelEvent).deltaX
  }

  onMouseDown = (e: Event): void => {
    e = browser.mouseEvent(e)
    e?.preventDefault?.()

    this._canClick = false

    this.mouse = {
      x: (e as MouseEvent).clientX,
      y: (e as MouseEvent).clientY,
      time: Date.now(),
      start: this.targetX
    }

    this.el.classList.add('is-dragging')
  }

  onMouseMove = (e: Event): void => {
    if (!this.mouse) return
    if (!this.canDrag) return

    e = browser.mouseEvent(e)
    e?.preventDefault?.()

    if (!this.mouse) return
    const frame = getFrame(e)
    const state = detectState(frame, this.mouse)

    const multiplier = detect.touch ? 1.3 : 1
    const delta = (e as MouseEvent).clientX - this.mouse.x

    this.targetX = this.mouse.start + delta * multiplier
    if (state === STATES.SWIPPING)
      scroll.lock()
    else
      scroll.unlock()
  }

  onMouseUp = (e: Event): void => {
    if (!this.mouse) return

    e = browser.mouseEvent(e)
    e?.preventDefault?.()

    this.onMouseMove(e)

    if (this.mouse) {
      const distance = Math.abs(this.mouse?.x - (e as MouseEvent).clientX) + Math.abs(this.mouse?.y - (e as MouseEvent).clientY)
      const duration = Date.now() - this.mouse?.time

      const durationThreshold = detect.touch ? 200 : 400
      const distanceThreshold = 20

      if ((distance < distanceThreshold && duration < durationThreshold) || !this.canDrag) this._canClick = true
      scroll.unlock()
    }

    this.mouse = null
    this.el.classList.remove('is-dragging')
  }

  onUpdate = () => {
    if (!this.enabled) return
    // if (mqStore.tabletPortrait.get()) return
    const previousX = this.x

    this.targetX -= .5
    this.x += (this.targetX - this.x) * decay
    if (this.modules.infiniteLine) this.modules.infiniteLine.offset = this.x
    if (previousX !== this.x) this.emit('update', this.x)
    // this.el.style.transform = `translate3d(${x}px, 0, 0)`
  }

  resize (): void {
    super.resize()
  }

  flush (): void {
    this.el.classList.remove('is-dragging')
    this.el.classList.remove('infinite-carousel')
    super.flush()
  }
}

export default InfiniteCarousel
