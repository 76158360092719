import { IComponentClass, ModulesMappping } from 'core/modulesMap'
import './BackInStockPanel.scss'
import PanelPage from 'navigation/pages/PanelPage'
import Form from 'components/form/Form'

import BackInStockForm from './back-in-stock-form/BackInStockForm'

type BackInStockPanelType = {
  refs: {
  }
}

const shopifySection = 'panel-back-in-stock'

class BackInStockPanel extends PanelPage<BackInStockPanelType> {
  getModuleParams (el: HTMLElement, componentConstructor: IComponentClass): Record<string, any> {
    return {
      ...super.getModuleParams(el, componentConstructor),
      section: shopifySection
    }
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      backInStockForm: ['.back-in-stock-form', BackInStockForm]
    }
  }
}

export default BackInStockPanel
