import store from 'stores/store'

const callback = () => {
  store.blurredOverlay.set(!!(store.filtersOpened.get()))

  if (store.panel.get() && store.menu.get())
    store.menu.set(null)
}

store.menu.listen(callback)
store.panel.listen(callback)
store.popin.listen(callback)
store.filtersOpened.listen(callback)
